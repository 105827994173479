<script lang="ts">
  import { EditorState, TextSelection } from 'prosemirror-state'
  import { findChildren } from 'prosemirror-utils'
  import { createEventDispatcher, onDestroy, onMount } from 'svelte'
  import type { Writable } from 'svelte/store'
  import type { DocMeta } from './docMetaStore'
  import DocSuggestions from './DocSuggestions.svelte'
  import type { EditorConnection } from './EditorConnection'
  import { pmSchema } from './pmSchema'
  import type { ScriptSchema } from './pmSchema'
  import type { Action } from './SuggestionPlugin'

  export let ec: EditorConnection
  let target: HTMLDivElement = null
  let suggestionsComponent: DocSuggestions = null
  export let data: Record<string, any> = null

  export let docMetaStore: Writable<DocMeta>

  const dispatch = createEventDispatcher<{
    change: EditorState<ScriptSchema>
    suggestionStateChange: {
      action: Action
      hasSuggestions: boolean
      suggestions: string[]
    }
    save: void
  }>()

  onMount(() => {
    const state = data
    ec.init(target, state.doc, [
      suggestionsComponent.getInputSuggestionsPlugin(),
    ])
    setTimeout(() => {
      if (
        ec.view.state.doc.content.size === 2 &&
        ec.view.state.selection.empty
      ) {
        suggestionsComponent.showInitialSteps()
      } else {
        const sel = TextSelection.create(
          ec.view.state.doc,
          state.selection.anchor,
          state.selection.head
        )
        ec.view.dispatch(ec.view.state.tr.setSelection(sel))
      }
      ec.view.focus()
      suggestionsComponent.initSuggestionsMethod()
    }, 500)
  })

  export function navigateToSceneIndex(index: number) {
    // console.log(id)
    const result = findChildren(ec.view.state.doc, (child) => {
      return child.type == pmSchema.nodes.sceneHeading
    })
    if (result.length === 0) {
      return
    }
    const child = result.find((v, i) => i === index)
    console.log(child)
    if (!child) {
      return
    }
    const id = child.node['attrs']['id']
    const sel = TextSelection.create(
      ec.view.state.doc,
      child.pos + child.node.nodeSize - 1,
      child.pos + child.node.nodeSize - 1
    )
    let tr = ec.view.state.tr.setSelection(sel)
    tr = tr.scrollIntoView()
    ec.view.dispatch(tr)
    ec.view.focus()
    // window.scrollTo({
    //   top: window.scrollY - 100,
    // })
    setTimeout(() => {}, 200)
    // also find element by id and scroll to it
    // const el = document.getElementById(id)
    // if (el) {
    //   // get rect
    //   const rect = el.getBoundingClientRect()
    //   // console.log(rect)
    //   // scroll to it smoothly
    //   // window.scrollTo(0, rect.top - 4 * 16)
    //   window.scrollTo({
    //     top: window.scrollY + rect.top - 4 * 16,
    //     behavior: 'smooth',
    //   })
    // }
  }

  export function scrollIntoView() {
    // const dom = ec.view.domAtPos(ec.view.state.selection.from)
    // if (dom.node) {
    //   //@ts-ignore
    //   dom.node.scrollIntoView({ behavior: 'smooth' })
    // }
  }

  onDestroy(() => {
    ec.view.destroy()
  })
</script>

<div class="doc__editor">
  <div class="target" bind:this={target} />
  <DocSuggestions
    on:change
    on:stateChange={(e) => {
      dispatch('suggestionStateChange', e.detail)
    }}
    store={docMetaStore}
    {ec}
    on:save
    bind:this={suggestionsComponent}
  />
</div>

<style lang="scss">
  :global {
    @import '../../styles/pm.scss';
    .doc__editor .custom__block {
      display: block;
      margin-bottom: 0.5rem;
      position: relative;
      // max-width: 60.1ch;
    }
    .doc__editor {
      sceneheading.custom__block {
        padding-left: 100px;
        position: relative;
        font-size: 1.05rem;
        font-weight: 500;
        max-width: 75.1ch;
      }
      p.custom__block,
      p {
        width: 75.1ch;
        margin: 0 auto;
      }
      sceneheading:before {
        content: attr(data-no);
        position: absolute;
        left: -1ch;
        top: 0;
        font-weight: 500;
        padding-left: 5.1ch;
      }
      character.custom__block {
        text-align: center;
        font-weight: 500;
        margin-bottom: 0;
      }
      action.custom__block {
        width: 75.1ch;
        padding-left: 100px;
        position: relative;
        margin-bottom: 1rem;
      }
      dialogue,
      parenthetical {
        width: 45.1ch;
        margin: 0 auto 0.5rem auto;
        text-align: left;
      }

      dialogue.custom__block {
        margin-bottom: 1rem;
      }
      parenthetical {
        text-align: center;
      }
      .is--serif {
        font-family: 'Noto Serif Malayalam', 'Baloo Chettan 2', serif;
      }
      pmtransition {
        text-align: right;
        padding-right: 50px;
      }
    }
  }
  .doc__editor {
    display: block;
    position: relative;
  }

  :global {
    .pm__scene__heading__editor {
      position: absolute;
      z-index: 1200;
      left: 0;
      top: 0;
      svg {
        height: 20px;
        margin-top: 4px;
      }
    }
    .pm__scene__heading__editor__handle {
      display: inline-block;
      cursor: pointer;
      &:hover {
        background: #eee;
      }
    }
    .pm__scene__heading__editor__wrapper {
      padding: 0.5rem;
      box-shadow: 0 0 0 1px #ccc;
      background: #f8f8f8;
      border-radius: 4px;
      &.hide {
        display: none;
      }
    }
  }
</style>
