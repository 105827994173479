import type { EditorState } from 'prosemirror-state'
import type { EditorView } from 'prosemirror-view'
import type { ScriptSchema } from './pmSchema'
import { pmSchema } from './pmSchema'
import crel from 'crel'

export class ScreenNumberEditor {
  item: HTMLDivElement
  view: EditorView<ScriptSchema>
  editor: HTMLInputElement
  wrapper: HTMLDivElement

  constructor(view: EditorView<ScriptSchema>) {
    // this.item = document.createElement('div')
    // this.item.className = 'pm__scene__heading__editor'
    this.view = view
    const wrapper = crel(
      'div',
      {
        class: 'pm__scene__heading__editor__wrapper hide',
      },
      [
        crel('div', { class: 'bd__wrapper' }),
        crel('input', {
          class: 'pm__scene__heading__editor__input input is-small',
          type: 'text',
        }),
      ]
    ) as HTMLDivElement
    this.wrapper = wrapper
    this.item = crel(
      'div',
      { class: 'pm__scene__heading__editor' },
      crel('div', {
        class: 'pm__scene__heading__editor__handle',
      }),
      wrapper
    )

    const handle = this.item.querySelector(
      '.pm__scene__heading__editor__handle'
    )
    this.onClick = this.onClick.bind(this)
    handle.innerHTML = `<svg data-svg-carbon-icon="Edit32" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" fill="currentColor" width="32" height="32" preserveAspectRatio="xMidYMid meet" class="s-asL19mKj2_tx"><path d="M25.4 9c.8-.8.8-2 0-2.8 0 0 0 0 0 0l-3.6-3.6c-.8-.8-2-.8-2.8 0 0 0 0 0 0 0l-15 15V24h6.4L25.4 9zM20.4 4L24 7.6l-3 3L17.4 7 20.4 4zM6 22v-3.6l10-10 3.6 3.6-10 10H6z" class="s-asL19mKj2_tx"></path></svg>`
    handle.addEventListener('click', this.onClick)

    const editor = this.item.querySelector('.pm__scene__heading__editor__input')
    this.editor = editor as HTMLInputElement
    // textinput value on change listener
    this.validateInput = this.validateInput.bind(this)
    editor.addEventListener('input', this.validateInput)

    this.onSubmit = this.onSubmit.bind(this)
    editor.addEventListener('keydown', this.onSubmit)

    view.dom.parentNode.appendChild(this.item)

    this.update(view, null)
  }

  validateInput(e: InputEvent) {
    //@ts-ignore
    const value = e.target.value as string
    const isCorrect = value.match(/^[\d]+\s?[a-zA-Z]?$/gi)

    if (!isCorrect) {
      this.editor.classList.add('is-danger')
    } else {
      this.editor.classList.remove('is-danger')
    }

    return isCorrect
  }

  onSubmit(e: KeyboardEvent) {
    if (e.key === 'Enter') {
      e.preventDefault()
      //@ts-ignore
      const value: string = e.target.value as string
      const isCorrect = value.match(/^[\d]+\s?[a-zA-Z]?$/gi)
      if (!isCorrect) {
        e.preventDefault()
        return
      }
      const selection = this.view.state.selection
      const tr = this.view.state.tr
      tr.setNodeMarkup(
        selection.from - selection.$from.parentOffset - 1,
        null,
        {
          no: value,
        }
      )
      tr.scrollIntoView()
      this.wrapper.classList.add('hide')
      this.view.dispatch(tr)
      this.view.focus()
    }
  }

  onClick(e: MouseEvent) {
    // console.log(e)
    // get selection
    const selection = this.view.state.selection
    const node = selection.$from.node(selection.$from.depth)
    const sceneNumber = node.attrs.no
    console.log({ sceneNumber })
    this.editor.value = sceneNumber
    this.wrapper.classList.remove('hide')

    this.editor.focus()
  }

  update(view: EditorView, lastState: EditorState<ScriptSchema> | null) {
    // console.log('update')
    // if last selection is null, dont do anythng
    if (!lastState || view.state.selection.from === lastState.selection.from) {
      this.item.style.display = 'none'
      return
    }
    // get current node from view
    const selection = view.state.selection
    const node = selection.$from.node(selection.$from.depth)
    if (node.type === pmSchema.nodes.sceneHeading) {
      let { from, to } = view.state.selection
      let start = view.coordsAtPos(from)
      const rect = view.dom.getBoundingClientRect()
      this.item.style.top = `${start.top - rect.top}px`
      this.item.style.display = 'block'
    } else {
      this.item.style.display = 'none'
      this.wrapper.classList.add('hide')
    }
    return
  }
}
