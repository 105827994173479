import {
  chainCommands,
  deleteSelection,
  joinBackward,
  joinForward,
  selectNodeBackward,
  selectNodeForward,
  splitBlock,
} from 'prosemirror-commands'
import { keymap } from 'prosemirror-keymap'
import { EditorState, Plugin } from 'prosemirror-state'
import { history, redo, undo } from 'prosemirror-history'
import { pmSchema } from './pmSchema'
import type { ScriptSchema } from './pmSchema'
import { EditorView } from 'prosemirror-view'
import { v4 as uuidv4 } from 'uuid'
import type { Slice } from 'prosemirror-model'
import { ScreenNumberEditor } from './SceneNumberEditorPlugin'

let backspace = chainCommands(deleteSelection, joinBackward, selectNodeBackward)
let del = chainCommands(deleteSelection, joinForward, selectNodeForward)

export class EditorConnection {
  state: EditorState<ScriptSchema>
  view: EditorView<ScriptSchema>
  constructor() {}
  init(el: HTMLDivElement, doc: any, plugins: Plugin[]) {
    this.state = this.getEditorState(doc, plugins)
    this.view = new EditorView<ScriptSchema>(el, {
      state: this.state,
      attributes: {
        //@ts-ignore
        spellcheck: false,
        'data-id': 'test-pm',
      },
      transformPastedHTML: (html: string) => {
        console.log(html)
        return html.replace(
          /(<sceneheading[\s\S]+?id=\")([^\"]+)/gim,
          '$1' + uuidv4()
        )
      },
      transformPasted: (slice: Slice<ScriptSchema>) => {
        return slice
      },
    })
  }

  getEditorState(doc, plugins: Plugin[]) {
    const screenEditorPlugin = new Plugin({
      view(v) {
        return new ScreenNumberEditor(v)
      },
    })

    const totalPlugins = [
      ...plugins,
      screenEditorPlugin,
      keymap({
        Enter: chainCommands(splitBlock),
        Backspace: backspace,
        Delete: del,
        'Mod-z': undo,
        'Mod-y': redo,
      }),
      history(),
    ]

    const state = EditorState.create({
      schema: pmSchema,
      doc: doc ? pmSchema.nodeFromJSON(doc) : null,
      plugins: totalPlugins,
    })

    return state
  }
}
