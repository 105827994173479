import localforage from 'localforage'
import { ax } from '../ax'

localforage.config({
  driver: localforage.INDEXEDDB,
  name: 'suggestions',
  version: 1.0,
  storeName: 'trans',
})

const db = localforage.createInstance({
  name: 'knit_suggestions',
})

type Translation = {
  text: string
  suggestions: string[]
}

export async function getSuggestionFromStore(
  word: string
): Promise<Translation> {
  const data = await db.getItem(word)
  if (data) {
    return {
      text: word.toLowerCase(),
      //@ts-ignore
      suggestions: data,
    }
  }
  const result = await ax.$get(
    `https://inputtools.google.com/request?text=${word}&itc=ml-t-i0-und&num=13&cp=0&cs=1&ie=utf-8&oe=utf-8`
  )
  await db.setItem(word, result[1][0][1])
  return {
    text: word,
    suggestions: result[1][0][1],
  }
}
