<script lang="ts">
  import { createEventDispatcher } from 'svelte'

  const dispatch = createEventDispatcher<{
    hide: {}
  }>()

  function handleKeydown(e: KeyboardEvent) {
    // if esc key
    if (e.key === 'Escape') {
      dispatch('hide')
    }
  }
</script>

<svelte:window on:keydown={handleKeydown} />

<div class="faq">
  <div class="modal is-active">
    <div class="modal-background" />
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">Crash Course</p>
        <button
          class="delete"
          aria-label="close"
          on:click={(e) => {
            e.preventDefault()
            dispatch('hide')
          }}
        />
      </header>
      <section class="modal-card-body">
        <div class="content">
          <h5 class="title is-5 ">What is weave</h5>
          <p class="">
            Weave is a basic script writing tool designed for malayalam
          </p>
          <h5 class="title is-5 ">How can I Use it?</h5>
          <p class="">
            Just typing should work in most cases. Make sure that you are
            connected to the internet.
          </p>
          <ul>
            <li>
              Typing in <i>Manglish</i> would bring you malayalam text
              suggestions. Use your arrow keys to navigate through the
              Suggestions and press
              <code>Enter</code> to select any suggestion
            </li>
            <li>
              When you need to switch between script block types, press <code
                >Tab</code
              >. You can see the current block type in the bottom bar of the
              document
            </li>
            <li>
              When you want to create a new block, press <code>Enter</code> while
              placing your cursor on an empty block. Suggestions will ask you about
              which block you want to create
            </li>
          </ul>
          <h5 class="title is-5 ">
            I am in a character block, How can i create a new scene from there?
          </h5>
          <ol>
            <li>
              If character suggestions are showing, press <code>Esc</code>
            </li>
            <li>
              Now press <code>Enter</code>, new suggestions for creating blocks
              will appear
            </li>
            <li>
              Select <code>Scene</code> from the suggestions and press
              <code>Enter</code>
            </li>
          </ol>
          <h5 class="title is-5 ">
            How to ignore suggestions? I also want to type some English text
          </h5>
          <p class="">
            Press <code>Esc</code> when you do not want to add the suggestions
          </p>
        </div>
      </section>
    </div>
  </div>
</div>

<style lang="scss">
  .faq {
    position: relative;
    z-index: 500;
  }
</style>
