import App from './App.svelte'
import './app.css'
import './styles/main.scss'
import * as Sentry from '@sentry/browser'
import { BrowserTracing } from '@sentry/tracing'

console.log(import.meta.env.MODE)
if (import.meta.env.MODE === 'production') {
  Sentry.init({
    dsn: import.meta.env.VITE_SENTRY_DSN,
    integrations: [new BrowserTracing()],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  })
}

// import.meta.env.VITE_SENTRY_DSN

const app = new App({
  target: document.getElementById('app'),
})

export default app
