<script lang="ts">
  import { ax } from '../ax'
  import { updateSettingsStore } from '../settingsStore'

  export let hideMainSidebar: number = 0

  async function logout() {
    try {
      const data = await ax.$get('/api/users/logout')
      updateSettingsStore({ user: null })
    } catch (error) {}
  }
</script>

<div
  class="main__sidebar flex w-14 py-8 h-screen
  light-border border-r
  mr-8
  "
  class:hidden={hideMainSidebar}
>
  <div class="bottom flex flex-grow flex-col justify-end items-center">
    <button
      class="icon__button"
      on:click={(e) => {
        logout()
      }}
    >
      <svg
        data-svg-carbon-icon="Logout24"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 32 32"
        fill="currentColor"
        width="24"
        height="24"
        preserveAspectRatio="xMidYMid meet"
      >
        <path
          d="M6,30H18a2.0023,2.0023,0,0,0,2-2V25H18v3H6V4H18V7h2V4a2.0023,2.0023,0,0,0-2-2H6A2.0023,2.0023,0,0,0,4,4V28A2.0023,2.0023,0,0,0,6,30Z"
        /><path
          d="M20.586 20.586L24.172 17 10 17 10 15 24.172 15 20.586 11.414 22 10 28 16 22 22 20.586 20.586z"
        />
      </svg>
    </button>
  </div>
</div>
