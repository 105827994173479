import { writable } from 'svelte/store'

const store = writable<{
  user: null | Record<string, any>
}>({
  user: null,
})

export function getSettingsStore() {
  return store
}

export function updateSettingsStore(data: any) {
  store.update((s) => ({ ...s, ...data }))
}
