<script lang="ts">
  import { createEventDispatcher } from 'svelte'
  import { ax } from '../ax'
  import { showAxiosError, showToast } from '../common/toastStore'

  export let data: {
    id: number
    title: string
    description: string
    uid: string

    author_id: number
    project_id: number
    team_id: number
    long_description: string
    cover_footer: string

    type: 'draft' | 'plain' | 'script'
    data: any
    created_at: Date
    updated_at: Date
  } = null

  async function saveDocument(e: SubmitEvent) {
    e.preventDefault()
    try {
      const response = await ax.$post(`/api/documents/${data.id}`, {
        ...data,
      })
      showToast('Document saved')
    } catch (error) {
      showAxiosError(error)
    }
  }

  const dispatch = createEventDispatcher<{
    dismiss: () => void
  }>()
</script>

<input type="checkbox" id="script-detaile-edit-modal" class="modal-toggle" />
<div class="modal modal-open">
  <div class="modal-box relative">
    <form action="" method="post" on:submit={saveDocument}>
      <label
        for="script-detaile-edit-modal"
        on:click={(e) => {
          e.preventDefault()
          dispatch('dismiss')
        }}
        class="btn btn-sm btn-circle absolute right-2 top-2">✕</label
      >
      <div class="form-control w-full max-w-xs">
        <label class="label" for="script-details-input-title">
          <span class="label-text">Script Title?</span>
        </label>
        <input
          type="text"
          placeholder="Title"
          class="input input-bordered w-full max-w-xs"
          bind:value={data.title}
          id="script-details-input-title"
        />
      </div>
      <div class="form-control">
        <label class="label" for="script-details-input-desc">
          <span class="label-text">Description</span>
          <span class="label-text-alt">Start with written by</span>
        </label>
        <textarea
          id="script-details-input-desc"
          class="textarea textarea-bordered h-24"
          placeholder="Description"
          bind:value={data.long_description}
        />
      </div>
      <div class="form-control">
        <label class="label" for="script-details-input-footer">
          <span class="label-text">Script Footer</span>
          <span class="label-text-alt">Your name and other contact details</span
          >
        </label>
        <textarea
          id="script-details-input-footer"
          class="textarea textarea-bordered h-24"
          placeholder="Script Footer"
          bind:value={data.cover_footer}
        />
      </div>
      <div class="form-control mt-4">
        <button class="btn btn-accent">Update</button>
      </div>
    </form>
  </div>
</div>
