<script lang="ts">
  import { SvelteToast } from '@zerodevx/svelte-toast'
  import type { SvelteToastOptions } from '@zerodevx/svelte-toast'
  import { onMount } from 'svelte'
  import { Route } from 'tinro'
  import { ax } from './lib/ax'
  import LoginPanel from './lib/common/LoginPanel.svelte'
  import { getSettingsStore, updateSettingsStore } from './lib/settingsStore'
  import DeskPage from './routes/DeskPage.svelte'
  import DocumentPage from './routes/DocumentPage.svelte'
  import { router } from 'tinro'
  import MainSidebar from './lib/common/MainSidebar.svelte'

  const settings = getSettingsStore()
  let hideMainSidebar = 0

  $: loggedIn = $settings.user !== null

  onMount(() => {
    init()

    const unsub = router.subscribe((val) => {
      if (!val) {
        return
      }
      if (val.path === '/') {
        hideMainSidebar = 0
      } else {
        hideMainSidebar = 1
      }
    })

    return () => {
      unsub()
    }
  })

  const toastOptions: SvelteToastOptions = {}

  async function init() {
    try {
      const data = await ax.$get('/api/init')
      if (data && data.user) {
        updateSettingsStore({ user: data.user })
      }
    } catch (error) {}
  }
</script>

{#if loggedIn}
  <main class="flex">
    <MainSidebar bind:hideMainSidebar />
    <div class="route__wrapper flex flex-grow">
      <Route path="/">
        <DeskPage />
      </Route>
      <Route path="/desk">
        <DeskPage />
      </Route>
      <Route path="/documents/:uid">
        <DocumentPage />
      </Route>
    </div>
  </main>
{:else}
  <LoginPanel />
{/if}

<SvelteToast options={toastOptions} />

<style lang="scss">
</style>
