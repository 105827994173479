<script lang="ts">
  import { captureException } from '@sentry/browser'
  import type { EditorState } from 'prosemirror-state'
  import { onDestroy, onMount, tick } from 'svelte'
  import { meta } from 'tinro'
  import { ax } from '../lib/ax'
  import ClapboardIcon from '../lib/common/icons/ClapboardIcon.svelte'
  import DashboardIcon from '../lib/common/icons/DashboardIcon.svelte'
  import DownloadIcon from '../lib/common/icons/DownloadIcon.svelte'
  import HelpIcon from '../lib/common/icons/HelpIcon.svelte'
  import PencilIcon from '../lib/common/icons/PencilIcon.svelte'
  import ToggleThemeIcon from '../lib/common/icons/ToggleThemeIcon.svelte'
  import ZenModeIcon from '../lib/common/icons/ZenModeIcon.svelte'
  import { debounce } from '../lib/common/minimal.lodash'
  import { getPageSettingsStore } from '../lib/docs/pageSettingsStore'
  import SceneLister from '../lib/docs/SceneLister.svelte'
  import TourGuide from '../lib/docs/TourGuide.svelte'
  import FaqModal from '../lib/modals/FAQModal.svelte'
  import ScriptDetailsEditorModal from '../lib/modals/ScriptDetailsEditorModal.svelte'
  import DocEditor from '../lib/pm/DocEditor.svelte'
  import { getDocMetaStore } from '../lib/pm/docMetaStore'
  import { EditorConnection } from '../lib/pm/EditorConnection'

  const route = meta()

  onMount(() => {
    getDocument()
  })

  // TODO - when changing url , check if document is has unsaved changes and ask user to save

  let data: Record<string, any> | null = null
  let tourGuide: TourGuide = null
  let showTourGuide = false
  const pageSettingsStore = getPageSettingsStore()

  async function getDocument() {
    try {
      let res = await ax.$get(`/api/documents/uid/${route.params.uid}`)
      if (res) {
        data = res
        checkForGuide()
      }
    } catch (error) {}
  }

  async function checkForGuide() {
    await tick().then()
    if (ec.view.state.doc.childCount < 10) {
      showTourGuide = true
    }
  }
  let showEditorModal = false

  let updateChanges = async (e: CustomEvent<EditorState>) => {
    metaStore.update((s) => {
      s.docChangedSinceSave = 1
      return s
    })
    if (tourGuide) {
      tourGuide.onChange()
    }
  }

  let saveChanges = async (e: CustomEvent<EditorState>) => {
    docEditor.scrollIntoView()
    try {
      await ax.$post('/api/documents/' + data.id, {
        data: e.detail.toJSON(),
      })
    } catch (error) {
      captureException(error)
    }
  }
  //@ts-ignore
  saveChanges = debounce(saveChanges, 100)

  let ec = new EditorConnection()
  const metaStore = getDocMetaStore()

  async function saveDocument() {
    metaStore.update((s) => {
      s.loading = 1
      return s
    })
    try {
      await saveChanges(new CustomEvent('change', { detail: ec.view.state }))
    } catch (error) {
    } finally {
      setTimeout(() => {
        metaStore.update((s) => {
          s.loading = 0
          s.docChangedSinceSave = 0
          return s
        })
      }, 500)
    }
  }

  onMount(() => {
    const timer = setInterval(() => {
      if ($metaStore.docChangedSinceSave === 1) {
        saveDocument()
      }
    }, 10 * 1000)
    return () => {
      clearInterval(timer)
    }
  })

  let hideScenes = 1
  let showFaqModal = 0

  $: scenes = $metaStore.sceneHeadings
  // $: console.log($docMeta)
  let docEditor: DocEditor = null

  function navigateToScene(e: { index: number }) {
    // docEditor.navigateToScene(scene.id)
    console.log({ index: e.index })
    docEditor.navigateToSceneIndex(e.index)
  }

  const themeList = [
    {
      type: 'light',
      value: 'light',
    },
    {
      type: 'dark',
      value: 'black',
    },
    {
      type: 'dark',
      value: 'dark',
    },
    {
      type: 'light',
      value: 'bumblebee',
    },
    {
      type: 'dark',
      value: 'halloween',
    },
    {
      type: 'light',
      value: 'garden',
    },
    {
      type: 'dark',
      value: 'forest',
    },
    {
      type: 'light',
      value: 'autumn',
    },
    {
      type: 'dark',
      value: 'synthwave',
    },
  ]
  let activeTheme = themeList[0]

  function toggleTheme() {
    // select next theme from themeList
    let index = themeList.findIndex((t) => t.value === activeTheme.value)
    index = (index + 1) % themeList.length
    activeTheme = themeList[index]
    const html = document.querySelector('html')
    const theme = html.getAttribute('data-theme')
    html.setAttribute('data-theme', activeTheme.value)
    html.setAttribute('data-theme-type', activeTheme.type)
  }
</script>

{#if showFaqModal === 1}
  <FaqModal
    on:hide={(e) => {
      showFaqModal = 0
    }}
  />
{/if}
<div class="doc container">
  {#if data}
    <div class="center__wrapper">
      <div class="page__wrapper" class:align--center={hideScenes === 1}>
        {#if hideScenes === 0}
          <SceneLister
            bind:scenes
            on:navigate={(e) => {
              navigateToScene(e.detail)
            }}
          />
        {/if}
        <div class="page__holder" class:shift--right={hideScenes === 0}>
          <div
            class="box border py-8 light-border rounded-sm mt-12 min-h-screen"
          >
            <DocEditor
              on:save={saveDocument}
              docMetaStore={metaStore}
              {ec}
              on:change={updateChanges}
              on:suggestionStateChange={(e) => {
                if (tourGuide) {
                  tourGuide.updateActionAndSuggestionsState(e)
                }
              }}
              data={data.data}
              bind:this={docEditor}
            />
            {#if showTourGuide}
              <TourGuide
                {ec}
                bind:this={tourGuide}
                on:dismiss={(e) => {
                  showTourGuide = false
                }}
              />
            {/if}
          </div>
        </div>
      </div>
    </div>
  {/if}
  <div
    class="sidebar border-r light-border z-50 bg-base-100 w-16 fixed left-0 top-0 h-screen flex"
    class:-left-20={$pageSettingsStore.zenMode}
  >
    <div class="sidebar__content py-4">
      <div class="sidebar__content__item">
        <button
          class="icon__button"
          data-tip="Toggle Scene List"
          on:click={(e) => {
            hideScenes = hideScenes === 0 ? 1 : 0
          }}
        >
          <ClapboardIcon />
        </button>
        {#if data && showEditorModal}
          <ScriptDetailsEditorModal
            on:dismiss={(e) => {
              showEditorModal = false
            }}
            {data}
          />
        {/if}
        <button
          data-tip="Edit script details"
          on:click={(e) => {
            showEditorModal = true
          }}
          class="icon__button"
        >
          <PencilIcon />
        </button>
      </div>

      <div class="bottom__icons">
        {#if data}
          <a
            href={'/api/documents/uid/' + data.uid + '/download/international'}
            target="_blank"
            class="icon__button"
            data-tip="Download as pdf"
          >
            <DownloadIcon />
          </a>
        {/if}
        <a href="/" class="icon__button" data-tip="Back to Desk">
          <DashboardIcon />
        </a>
        <button
          class="icon__button"
          data-tip="Tips?"
          on:click={(e) => {
            showFaqModal = 1
          }}
        >
          <HelpIcon />
        </button>

        <button
          class="icon__button"
          data-tip="Toggle Theme"
          on:click={toggleTheme}
        >
          <ToggleThemeIcon />
        </button>
        <button
          class="icon__button"
          data-tip="Enter Zen Mode"
          on:click={(e) => {
            $pageSettingsStore.zenMode = !$pageSettingsStore.zenMode
          }}
        >
          <ZenModeIcon />
        </button>
      </div>
    </div>
  </div>
</div>

<style lang="scss">
  @import '../styles/vars.scss';
  .center__wrapper {
    display: flex;
    justify-content: center;
    width: 100%;
  }
  .page__wrapper {
    display: flex;
  }
  .page__wrapper {
    display: flex;
    flex: 1;
    // margin-bottom: 10rem;
  }
  // .sidebar {
  //   position: fixed;
  //   left: 0;
  //   top: 0;
  //   height: 100%;
  //   width: 4rem;
  //   display: flex;
  // }

  .align--center {
    display: flex;
    justify-content: center;
  }
  .page__holder {
    padding-top: 1rem;
    position: relative;
    display: flex;
    align-items: flex-start;
    padding-bottom: 80vh;
    .box {
      min-width: 21cm;
    }
  }
  :global {
    .page__holder .ProseMirror {
      content-visibility: auto;
    }
  }
  .page__holder.shift--right {
    margin-left: 2rem;
  }
  .sidebar__content__item {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  .sidebar__content {
    display: flex;
    flex-direction: column;
    flex: 1;
  }
  .icon__button {
    margin-bottom: 1rem;
    position: relative;
    display: block;
  }
  .bottom__icons {
    margin-top: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .icon__button:hover::before {
    content: attr(data-tip);
    position: absolute;
    right: 0;
    top: 0;
    background: $grey-darker;
    color: $white;
    padding: 0.25rem;
    margin-right: -200%;
    margin-top: 2rem;
    border-radius: 4px;
    // dont break word
    white-space: nowrap;
    font-size: 0.8rem;
  }
</style>
