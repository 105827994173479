export const envSuggestions = ['INT', 'EXT', 'EXT/INT']

export const timeSuggestions = [
  'DAY',
  'NIGHT',
  'AFTERNOON',
  'MORNING',
  'EVENING',
  'LATER',
  'MOMENTS LATER',
  'CONTINUOUS',
  'THE NEXT DAY',
  'MAGIC HOUR',
]

export const transitionSuggestions = [
  'Cut to',
  'Dissolve to',
  'Fade in',
  'Fade out',
  'Fade to',
  'Jump Cut to',
  'Match cut to',
  'Smash cut to',
  'Wipe to',
  'Time cut',
].map((item) => item.toUpperCase())
