import { toast } from '@zerodevx/svelte-toast'
import type { AxiosError } from 'axios'

export function showToast(message: string) {
  toast.push(message, {})
}

export function showAxiosError(e: AxiosError) {
  if (e.response && e.response.data && e.response.data.message) {
    showToast(e.response.data.message)
  } else {
    showToast('unknown error, contact support')
  }
}
