import { writable } from 'svelte/store'

export type Scene = {
  no: number | string
  id: string
  text: string
}

export type DocMeta = {
  characters: string[]
  locations: string[]
  sceneHeadings: Scene[]
  loading: number
  docChangedSinceSave: number
}

export function getDocMetaStore() {
  return writable<DocMeta>({
    characters: [],
    locations: [],
    sceneHeadings: [],
    loading: 0,
    docChangedSinceSave: 0,
  })
}
