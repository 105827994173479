<svg
  width="24"
  height="24"
  viewBox="0 0 24 24"
  fill="currentColor"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    d="M12 1.5C9.9233 1.5 7.89323 2.11581 6.16651 3.26957C4.4398 4.42332 3.09398 6.0632 2.29926 7.98182C1.50454 9.90045 1.29661 12.0116 1.70175 14.0484C2.1069 16.0852 3.10693 17.9562 4.57538 19.4246C6.04383 20.8931 7.91475 21.8931 9.95155 22.2982C11.9884 22.7034 14.0996 22.4955 16.0182 21.7007C17.9368 20.906 19.5767 19.5602 20.7304 17.8335C21.8842 16.1068 22.5 14.0767 22.5 12C22.5 9.21523 21.3938 6.54451 19.4246 4.57538C17.4555 2.60625 14.7848 1.5 12 1.5ZM12 21C10.22 21 8.47991 20.4722 6.99987 19.4832C5.51983 18.4943 4.36627 17.0887 3.68508 15.4442C3.0039 13.7996 2.82567 11.99 3.17293 10.2442C3.5202 8.49836 4.37737 6.89471 5.63604 5.63604C6.89471 4.37737 8.49836 3.5202 10.2442 3.17293C11.99 2.82567 13.7996 3.0039 15.4442 3.68508C17.0887 4.36627 18.4943 5.51983 19.4832 6.99987C20.4722 8.47991 21 10.22 21 12C21 14.3869 20.0518 16.6761 18.364 18.364C16.6761 20.0518 14.3869 21 12 21Z"
  />
  <path
    d="M12 18.75C12.6213 18.75 13.125 18.2463 13.125 17.625C13.125 17.0037 12.6213 16.5 12 16.5C11.3787 16.5 10.875 17.0037 10.875 17.625C10.875 18.2463 11.3787 18.75 12 18.75Z"
  />
  <path
    d="M12.75 6H11.625C11.1815 5.99901 10.7422 6.08563 10.3323 6.25489C9.92235 6.42415 9.5499 6.67272 9.23631 6.98631C8.92271 7.2999 8.67415 7.67235 8.50489 8.08227C8.33563 8.4922 8.24901 8.93151 8.25 9.375V9.75H9.75V9.375C9.75 8.87772 9.94754 8.40081 10.2992 8.04918C10.6508 7.69754 11.1277 7.5 11.625 7.5H12.75C13.2473 7.5 13.7242 7.69754 14.0758 8.04918C14.4275 8.40081 14.625 8.87772 14.625 9.375C14.625 9.87228 14.4275 10.3492 14.0758 10.7008C13.7242 11.0525 13.2473 11.25 12.75 11.25H11.25V14.625H12.75V12.75C13.6451 12.75 14.5035 12.3944 15.1365 11.7615C15.7694 11.1285 16.125 10.2701 16.125 9.375C16.125 8.47989 15.7694 7.62145 15.1365 6.98852C14.5035 6.35558 13.6451 6 12.75 6Z"
  />
</svg>
