export const inscriptMaps = {
  a: '\u0D4B',
  b: '\u0D35',
  c: '\u0D2E',
  d: '\u0D4D',
  e: '\u0D3E',
  f: '\u0D3F',
  g: '\u0D41',
  h: '\u0D2A',
  i: '\u0D17',
  j: '\u0D30',
  k: '\u0D15',
  l: '\u0D24',
  m: '\u0D38',
  n: '\u0D32',
  o: '\u0D26',
  p: '\u0D1C',
  q: '\u0D57',
  r: '\u0D40',
  s: '\u0D47',
  t: '\u0D42',
  u: '\u0D39',
  v: '\u0D28',
  w: '\u0D48',
  x: '\u0D02',
  y: '\u0D2C',
  z: '\u0D46',
  A: '\u0D13',
  B: '\u0D34',
  C: '\u0D23',
  D: '\u0D05',
  E: '\u0D06',
  F: '\u0D07',
  G: '\u0D09',
  H: '\u0D2B',
  I: '\u0D18',
  J: '\u0D31',
  K: '\u0D16',
  L: '\u0D25',
  M: '\u0D36',
  N: '\u0D33',
  O: '\u0D27',
  P: '\u0D1D',
  Q: '\u0D14',
  R: '\u0D08',
  S: '\u0D0F',
  T: '\u0D0A',
  U: '\u0D19',
  V: 'V',
  W: '\u0D10',
  X: '\u0D02',
  Y: '\u0D2D',
  Z: '\u0D0E',
  '1': '\u0D67',
  '2': '\u0D68',
  '3': '\u0D69',
  '4': '\u0D6A',
  '5': '\u0D6B',
  '6': '\u0D6C',
  '7': '\u0D6D',
  '8': '\u0D6E',
  '9': '\u0D6F',
  '0': '\u0D66',
  '`': '\u0D4A',
  _: '\u0D03',
  '~': '\u0D12',
  '+': '\u0D0B',
  '=': '\u0D43',
  '[': '\u0D21',
  ']': '\u200D',
  '{': '\u0D22',
  '}': '\u0D1E',
  ':': '\u0D1B',
  ';': '\u0D1A',
  '': '\u0D1A',
  '<': '\u0D37',
  '>': '\u200D',
  '/': '\u0D2F',
  '"': '\u0D20',
  "'": '\u0D1F',
  '\\': '\u200C',
}

const zwnjMaps = [
  {
    char: 'ണ്',
    unicode: '0D7A',
  },
  {
    char: 'ന്',
    unicode: '0D7B',
  },
  {
    char: 'ര്',
    unicode: '0D7C',
  },
  {
    char: 'ല്',
    unicode: '0D7D',
  },
  {
    char: 'ള്',
    unicode: '0D7E',
  },
  {
    char: 'ക്',
    unicode: '0D7F',
  },
  {
    char: 'മ്',
    unicode: '0D54',
  },
  {
    char: 'യ്',
    unicode: '0D55',
  },
  {
    char: 'ഴ്',
    unicode: '0D56',
  },
]

export function getZwnjMatch(str: string): string | null {
  const needle = zwnjMaps.find((t) => {
    return t.char === str
  })
  if (!needle) {
    return null
  }
  let d = parseInt('0x' + needle.unicode)
  return String.fromCharCode(d)
}

export function getSingleKeyMatch(str: string) {
  return inscriptMaps[str] || null
}
