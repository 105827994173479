<svg
  width="24"
  height="24"
  viewBox="0 0 24 24"
  fill="currentColor"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    d="M17.625 16.5H17.25V15H17.625C18.5201 15.0358 19.3928 14.7146 20.051 14.1069C20.7093 13.4993 21.0992 12.6551 21.135 11.76C21.1708 10.8649 20.8496 9.99223 20.2419 9.33397C19.6343 8.67572 18.7901 8.2858 17.895 8.25H17.25L17.175 7.635C17.0086 6.37234 16.3889 5.21321 15.4313 4.37354C14.4737 3.53387 13.2436 3.07091 11.97 3.07091C10.6964 3.07091 9.46626 3.53387 8.50868 4.37354C7.5511 5.21321 6.93139 6.37234 6.765 7.635L6.75 8.25H6.105C5.20989 8.2858 4.36567 8.67572 3.75805 9.33397C3.15044 9.99223 2.8292 10.8649 2.865 11.76C2.9008 12.6551 3.29072 13.4993 3.94897 14.1069C4.60723 14.7146 5.47989 15.0358 6.375 15H6.75V16.5H6.375C5.1723 16.4924 4.01487 16.0404 3.12524 15.2311C2.2356 14.4217 1.67656 13.312 1.5556 12.1154C1.43463 10.9188 1.76028 9.71968 2.46994 8.74864C3.17959 7.7776 4.22316 7.10316 5.4 6.855C5.72378 5.34489 6.55563 3.99148 7.75673 3.0206C8.95784 2.04972 10.4556 1.5201 12 1.5201C13.5444 1.5201 15.0422 2.04972 16.2433 3.0206C17.4444 3.99148 18.2762 5.34489 18.6 6.855C19.7768 7.10316 20.8204 7.7776 21.5301 8.74864C22.2397 9.71968 22.5654 10.9188 22.4444 12.1154C22.3234 13.312 21.7644 14.4217 20.8748 15.2311C19.9851 16.0404 18.8277 16.4924 17.625 16.5Z"
  />
  <path
    d="M12.75 19.6275V10.5H11.25V19.6275L9.3075 17.6925L8.25 18.75L12 22.5L15.75 18.75L14.6925 17.6925L12.75 19.6275Z"
  />
</svg>
