<script lang="ts">
  import { Fragment } from 'prosemirror-model'
  import { EditorState, TextSelection } from 'prosemirror-state'
  import type { EditorView } from 'prosemirror-view'
  import { createEventDispatcher, onDestroy, onMount } from 'svelte'
  import type { Writable } from 'svelte/store'
  import { fly } from 'svelte/transition'
  import { v4 as uuidv4 } from 'uuid'
  import type { DocMeta } from './docMetaStore'
  import type { EditorConnection } from './EditorConnection'
  import { getSingleKeyMatch, getZwnjMatch } from './inscript'
  import MetaWorker from './metaWorker?worker'
  import { pmSchema } from './pmSchema'
  import type { ScriptSchema } from './pmSchema'
  import {
    envSuggestions,
    timeSuggestions,
    transitionSuggestions,
  } from './pmSuggestionTypes'
  import {
    Action,
    findNextBlock,
    findNextTabEntry,
    getParamsForSceneHeading,
    getSuggestionPluginAndKey,
    isCurrentNodeEmpty,
    isSelectionAtTheEndofBlock,
  } from './SuggestionPlugin'
  import { getSuggestionFromStore } from './TypeSuggestionStore'
  import DocToolbar from './DocToolbar.svelte'

  type TextOption = {
    text: string
    suggestions: string[]
  }

  export let ec: EditorConnection
  export let store: Writable<DocMeta>

  const worker = new MetaWorker()

  worker.onmessage = (e: MessageEvent<DocMeta>) => {
    store.update((s) => {
      return {
        ...s,
        ...e.data,
      }
    })
  }

  onDestroy(() => {
    worker.terminate()
  })

  let root: HTMLDivElement

  let output: TextOption = null
  let style = null
  let activeElementIndex = -1
  let lastTypedWords: string | null = null
  let scroller: HTMLElement = null
  let method: 'google' | 'ism' = 'google'
  let disableSuggestions = false

  const dispatch = createEventDispatcher<{
    change: EditorState<ScriptSchema>
    stateChange: {
      action: Action
      hasSuggestions: boolean
      suggestions: string[]
    }
    save: void
  }>()

  function scrollToElement(index: number) {
    if (index === -1) return
    if (!output || output.suggestions.length === 0) {
      return
    }
    const child = scroller.children[activeElementIndex] as HTMLDivElement
    //scroll to that child
    scroller.scrollTop = child.offsetTop - child.offsetHeight
  }
  $: scrollToElement(activeElementIndex)

  function detectNextNodeForBlankEntry(item: string) {
    switch (item) {
      case 'Scene':
        return pmSchema.nodes.sceneHeading
      case 'Action':
        return pmSchema.nodes.action
      case 'Character':
        return pmSchema.nodes.character
      case 'Transition':
        return pmSchema.nodes.transition
      case 'Dialogue':
        return pmSchema.nodes.dialogue
      default:
        return pmSchema.nodes.general
    }
  }

  function replaceCurrentBlockWith(view: EditorView, item: string) {
    const $from = ec.view.state.selection.$from
    const start = $from.start()
    const end = $from.end()
    const content = Fragment.fromJSON(pmSchema, [])
    const newNodeType = detectNextNodeForBlankEntry(item)
    let attrs: any = {}
    if (newNodeType === pmSchema.nodes.sceneHeading) {
      attrs = getParamsForSceneHeading(ec.view.state)
    }
    const newNode = newNodeType.createAndFill(attrs, content)
    const tr = view.state.tr.replaceRangeWith(start, end, newNode)
    const sel = TextSelection.create(tr.doc, start + newNode.nodeSize - 2)
    activeElementIndex = -1
    tr.setSelection(sel)
    view.dispatch(tr)
  }

  function selectItem(
    view: EditorView<ScriptSchema>,
    activeElementIndex: number,
    options: {
      includeSpace: boolean
      suffix: string
    } = {
      includeSpace: true,
      suffix: '',
    }
  ) {
    let index = activeElementIndex === -1 ? 0 : activeElementIndex
    if (!output || output.suggestions.length === 0) {
      return
    }
    const item = output.suggestions[index]
    if (lastMode === Action.NEW_BLOCK) {
      activeElementIndex = -1
      replaceCurrentBlockWith(view, item)
      return
    }
    const $from = ec.view.state.selection.$from
    const currentNode = $from.node($from.depth)
    let tr = view.state.tr

    let pos = view.state.selection.$from.pos
    let text = item
    let suffix = options.includeSpace ? ' ' : ''
    if (lastMode === Action.SHOW_ENV) {
      suffix = '. '
    } else if (lastMode === Action.SHOW_TIME) {
      suffix = ''
    } else if (lastMode === Action.SHOW_LOCATION) {
      suffix = ' - '
    }
    if (
      currentNode.type === pmSchema.nodes.character ||
      currentNode.type === pmSchema.nodes.parenthetical
    ) {
      suffix = ''
    }
    if (currentNode.type === pmSchema.nodes.transition) {
      suffix = ':'
    }
    if (options.suffix) {
      suffix += options.suffix
    }
    const textNode = view.state.schema.text(text + suffix)
    tr.replaceRangeWith(pos - lastTypedWords.length, pos, textNode)

    hideEverything()
    lastTypedWords = ''
    view.dispatch(tr)
    dispatch('change', view.state)

    if (currentNode.type === pmSchema.nodes.transition) {
      createNewBlock()
    }
  }
  let lastMode: Action = null
  let currentNodeType: string | null = null

  $: dispatch('stateChange', {
    action: lastMode,
    hasSuggestions: output !== null && output.suggestions.length > 0,
    suggestions: output ? output.suggestions : [],
  })

  export function showInitialSteps() {
    lastTypedWords = ''
    lastMode = Action.SHOW_ENV
    output = {
      text: '',
      suggestions: [...envSuggestions],
    }
    calculateStyles()
  }

  function replaceTextWithInscriptSuggestions(key: string): boolean {
    const suggestions = getSingleKeyMatch(key)
    const view = ec.view
    const lastTwoCharacters = ec.view.state.doc.textBetween(
      ec.view.state.selection.$from.pos - 2,
      ec.view.state.selection.$from.pos
    )

    if (lastTwoCharacters === 'ന്') {
      let match = null
      if (key === 'h') {
        match = 'മ്പ'
        // console.log('atchings')
      } else if (key === 'O') {
        match = 'ന്ധ'
      }
      if (match) {
        let tr = view.state.tr
        tr.replaceRangeWith(
          view.state.selection.$from.pos - 2,
          view.state.selection.$from.pos,
          pmSchema.text(match)
        )
        view.dispatch(tr)
        return true
      }
    }

    if (key === '&') {
      let tr = view.state.tr
      tr.insertText('ക്ഷ')
      view.dispatch(tr)
      return true
    }

    if ([']', 'J', 'O'].includes(key)) {
      let charMatch: string = null
      if (key === 'O' && lastTwoCharacters.endsWith('ത്')) {
        charMatch = 'ഝ'
      }
      //nte and chillu logic
      if (key === 'J' && lastTwoCharacters.endsWith('ൻ')) {
        charMatch = 'ന്റ'
      }
      if (charMatch) {
        let tr = view.state.tr
        tr.replaceRangeWith(
          charMatch === 'ഝ'
            ? view.state.selection.$from.pos - 2
            : view.state.selection.$from.pos - 1,
          view.state.selection.$from.pos,
          pmSchema.text(charMatch)
        )
        view.dispatch(tr)
        return true
      }
      const zwMatch = getZwnjMatch(lastTwoCharacters)
      if (zwMatch) {
        const view = ec.view
        const tr = ec.view.state.tr
        const node = pmSchema.text(zwMatch)
        tr.replaceRangeWith(
          view.state.selection.$from.pos - 2,
          view.state.selection.$from.pos,
          node
        )
        view.dispatch(tr)
        return true
      }
    }

    if (suggestions) {
      const view = ec.view
      const tr = ec.view.state.tr
      tr.insertText(suggestions)
      view.dispatch(tr)
      return true
    } else {
      const view = ec.view
      const tr = ec.view.state.tr
      tr.insertText(key)
      view.dispatch(tr)
      return true
    }

    // now all the custom conditions that we need to check

    return false
  }

  const { key, plugin } = getSuggestionPluginAndKey({
    suggestAction(action, text) {
      if (action === Action.SHOW_NORMAL_SUGGESTIONS && text.trim().length > 0) {
        if (method === 'google' && !disableSuggestions) {
          lastTypedWords = text
          showInputSuggestions(text)
        } else {
          lastTypedWords = text
          hideEverything()
        }
      } else if (action === Action.SHOW_TIME) {
        lastTypedWords = text
        output = {
          text: '',
          suggestions: [...timeSuggestions],
        }
        calculateStyles()
      } else if (action === Action.SHOW_ENV) {
        lastTypedWords = text
        output = {
          text: '',
          suggestions: [...envSuggestions],
        }
        calculateStyles()
      } else if (action === Action.SHOW_TRANSITION) {
        lastTypedWords = ''
        output = {
          text: '',
          suggestions: [...transitionSuggestions],
        }
        calculateStyles()
      } else if (action === Action.SHOW_CHARACTERS) {
        lastTypedWords = ''
        // let i = 0
        output = {
          text: '',
          suggestions: [
            ...$store.characters,
            // ...Array.from(Array(100).keys()).map((k) => `Character ${k + 1}`),
          ],
        }
        calculateStyles()
      } else if (action === Action.SHOW_LOCATION) {
        lastTypedWords = ''
        output = {
          text: '',
          suggestions: [...$store.locations],
        }
        calculateStyles()
      } else if (action === Action.HIDE_SUGGESTIONS) {
        hideEverything()
      }
      lastMode = action
    },
    updateAction(action) {},
    handleKeyDown: onKeyDown,
    detectCurrentNode: (type) => {
      currentNodeType = type.name
    },
    onUpdateState(state, e) {
      if (e.docChanged) {
        // console.log(e.docChanged, 'docChanged')
        dispatch('change', state)
      }
      worker.postMessage(state.doc.toJSON())
    },
  })

  onMount(() => {
    let typingMethod = localStorage.getItem('weave:inputSuggestions:method')
    if (['google', 'ism'].includes(typingMethod)) {
      method = typingMethod as 'google' | 'ism'
    } else {
      method = 'google'
    }
  })

  export function initSuggestionsMethod() {
    const tr = ec.view.state.tr
    const payload = {
      ...key.getState(ec.view.state),
      method: method,
    }
    tr.setMeta(plugin, payload)
    ec.view.dispatch(tr)
  }

  function highlightItem(direction: 'up' | 'down') {
    let suggestions = output.suggestions
    if (suggestions.length === 0) {
      return
    }
    let selectedIndex = suggestions.findIndex((item, index) => {
      return index === activeElementIndex
    })
    // console.log(selectedIndex, activeElementIndex)
    if (selectedIndex === -1) {
      // nothing selected
      if (direction === 'down') {
        selectedIndex = 0
      } else {
        selectedIndex = suggestions.length - 1
      }
    } else {
      activeElementIndex = 0
      if (direction === 'down') {
        selectedIndex++
        if (selectedIndex === suggestions.length) {
          selectedIndex = 0
        }
      } else {
        selectedIndex--
        if (selectedIndex < 0) {
          selectedIndex = suggestions.length - 1
        }
      }
    }
    activeElementIndex = selectedIndex
  }

  function createNewBlock() {
    let tr = ec.view.state.tr
    let pos = ec.view.state.selection.$to.end() + 1
    const $from = ec.view.state.selection.$from
    const currentNode = $from.node($from.depth)
    let nodeType = findNextBlock(currentNode.type)
    let attrs: any = {}
    if (nodeType === pmSchema.nodes.sceneHeading) {
      attrs = getParamsForSceneHeading(ec.view.state)
      attrs.id = uuidv4()
    }
    const node = nodeType.createAndFill(attrs, Fragment.fromJSON(pmSchema, []))
    tr.insert(pos, node)
    // hideEverything()
    const sel = TextSelection.create(tr.doc, pos + 1)
    tr.setSelection(sel)
    ec.view.dispatch(tr)
  }

  function alterCurrentBlock() {
    if (!isCurrentNodeEmpty(ec.view.state)) {
      return true
    }
    let tr = ec.view.state.tr
    let pos = ec.view.state.selection.to + 1
    const $from = ec.view.state.selection.$from
    const currentNode = $from.node($from.depth)
    const replaceNodeType = findNextTabEntry(currentNode.type)
    let attrs: any = {}
    if (replaceNodeType) {
      let innerContent: any = []
      if (replaceNodeType === pmSchema.nodes.parenthetical) {
        innerContent = [
          {
            type: 'text',
            text: '()',
          },
        ]
      } else if (replaceNodeType === pmSchema.nodes.sceneHeading) {
        attrs = getParamsForSceneHeading(ec.view.state)
      }
      const newNode = replaceNodeType.createAndFill(
        attrs,
        Fragment.fromJSON(pmSchema, innerContent)
      )
      const start = $from.start()
      tr.replaceRangeWith(start, pos, newNode)

      if (newNode.type === pmSchema.nodes.parenthetical) {
        tr.setSelection(TextSelection.create(tr.doc, start + 1))
      } else {
        tr.setSelection(TextSelection.create(tr.doc, start))
      }
      ec.view.dispatch(tr)
      dispatch('change', ec.view.state)
    }
    return true
  }

  function showNewBlockSuggestions() {
    lastMode = Action.NEW_BLOCK
    output = {
      text: '',
      suggestions: ['Scene', 'Action', 'Character', 'Dialogue', 'Transition'],
    }
    activeElementIndex = -1
    calculateStyles()
  }

  function scrollToViewPort() {
    const dom = ec.view.domAtPos(ec.view.state.selection.to)
    //@ts-ignore
    if (dom.node && dom.node.scrollIntoView) {
      //@ts-ignore
      const node: HTMLElement = dom.node
      // console.log(node)

      const rect = node.getBoundingClientRect()
      if (rect.y > window.innerHeight) {
        // window scroll top
        window.scroll(0, window.scrollY + rect.y - window.innerHeight / 2)
        // node.scrollIntoView({
        //   behavior: 'smooth',
        //   block: 'start',
        // })
      }

      // //@ts-ignore
      // dom.node.scrollIntoView({
      //   // behavior: 'smooth',
      //   block: 'center',
      //   inline: 'center',
      // })
    }
  }

  function createLineBreak() {
    let tr = ec.view.state.tr
    const $from = ec.view.state.selection.$from
    const currentNode = $from.node($from.depth)
    if (
      [pmSchema.nodes.action, pmSchema.nodes.dialogue].includes(
        currentNode.type
      )
    ) {
      const nodeType = pmSchema.nodes.hard_break
      tr.replaceSelectionWith(nodeType.create()).scrollIntoView()
      ec.view.dispatch(tr)
      return true
    }
    return false
  }

  function onKeyDown(
    view: EditorView<ScriptSchema>,
    e: KeyboardEvent
  ): boolean {
    let matchKey = 1
    scrollToViewPort()
    // check if Cmd+G or Ctrl+G is pressed
    if (e.metaKey || e.ctrlKey) {
      if (e.key === 'g') {
        disableSuggestions = !disableSuggestions
        return true
      }
    }
    if (!output || output.suggestions.length === 0) {
      if (e.key === 'Enter' && e.shiftKey) {
        return createLineBreak()
      }
      if (e.key === 'Enter' && isCurrentNodeEmpty(view.state)) {
        showNewBlockSuggestions()
        return true
      }
      if (e.key === 'Enter' && isSelectionAtTheEndofBlock(view.state)) {
        createNewBlock()
        return true
      }
      // if(e.key === 'Enter')
      if (e.key === 'Tab') {
        return alterCurrentBlock()
      }
      // if key matches characters in a keyboard
      // check if shift control and alt are pressed
      if (
        e.key &&
        e.key.length === 1 &&
        !e.metaKey &&
        !e.altKey &&
        !e.ctrlKey &&
        method === 'ism' &&
        !disableSuggestions
      ) {
        matchKey = e.key.charCodeAt(0)
        return replaceTextWithInscriptSuggestions(e.key)
      }
      return false
    }
    if (e.key === 'Tab') {
      return alterCurrentBlock()
    }
    if (e.key === 'Enter') {
      selectItem(view, activeElementIndex)
    } else if (e.key === 'Escape') {
      hideEverything()
    } else if (e.key == 'ArrowDown') {
      highlightItem('down')
    } else if (e.key == 'ArrowUp') {
      highlightItem('up')
    } else if (e.key.match(/\W/gi)) {
      selectItem(view, activeElementIndex, {
        includeSpace: false,
        suffix: e.key,
      })
    } else {
      matchKey = 0
      if (method === 'ism' && e.key.length === 1) {
        return replaceTextWithInscriptSuggestions(e.key)
      }
    }
    if (matchKey === 1) {
      return true
    }
    return false
  }

  function hideEverything() {
    output = null
    activeElementIndex = -1
  }

  let docData: any = null

  function calculateStyles() {
    const view = ec.view
    // get selection cordinates and text
    const rect = view.coordsAtPos(view.state.selection.to)
    const viewRect = view.dom.getBoundingClientRect()
    style = {
      top: rect.top - viewRect.top + 24,
      left: rect.left - viewRect.left + 8,
    }
  }

  let showInputSuggestions = async (word: string) => {
    try {
      const trans = await getSuggestionFromStore(word)
      if (trans && word === lastTypedWords) {
        output = {
          text: word,
          suggestions: trans.suggestions,
        }
        activeElementIndex = -1
        calculateStyles()
      }
    } catch (error) {
      console.error(error)
    }
  }

  export function getInputSuggestionsPlugin() {
    return plugin
  }

  function clickAndSelect(index) {
    selectItem(ec.view, index, {
      includeSpace: false,
      suffix: '',
    })
    if (!ec.view.hasFocus()) {
      ec.view.focus()
    }
  }

  function saveMethodPreference(val: string) {
    localStorage.setItem('weave:inputSuggestions:method', val)
    initSuggestionsMethod()
  }
</script>

<div class="doc__suggestions" bind:this={root}>
  {#if output && style && output.suggestions.length > 0}
    <!-- <pre>{activeElementIndex}</pre> -->
    <div
      class="panel is--ml--serif bg-base-100"
      transition:fly={{ y: 10, duration: 200 }}
      style="top: {style.top}px; left: {style.left}px"
    >
      <div class="translation">
        <ul class="translation__suggestions" bind:this={scroller}>
          {#each output.suggestions as suggestion, index (suggestion + '--' + index)}
            <li
              class="translation__suggestion"
              class:light-bg={index === activeElementIndex}
              on:click={(e) => {
                e.preventDefault()
                clickAndSelect(index)
              }}
            >
              {suggestion}
            </li>
          {/each}
        </ul>
      </div>
    </div>
  {/if}
</div>

<DocToolbar
  on:typingMethodChange={(e) => {
    saveMethodPreference(e.detail.typingMethod)
  }}
  metaStore={store}
  bind:currentNodeType
  bind:disableSuggestions
  bind:method
  on:save
/>

<!-- <pre>
  {JSON.stringify(docData, null, 2)}
</pre> -->
<style lang="scss">
  .panel {
    position: absolute;
    left: 0;
    top: 0;
  }
  .panel {
    // background: white;
    min-height: 20px;
    min-width: 150px;
    z-index: 100;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
    // padding: 0.5rem 0;
    z-index: 500;
  }
  .right {
    margin-left: auto;
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }
  .method__status {
    font-size: 0.7rem;
  }
  .translation__suggestion.is--active,
  .translation__suggestion:hover {
    // background: #eee;
    cursor: pointer;
  }
  .translation__suggestions {
    max-height: 200px;
    overflow-y: auto;
  }
  .translation__suggestions::-webkit-scrollbar {
    width: 5px;
  }
  .translation__suggestions::-webkit-scrollbar-track {
    background: #eee;
    border-radius: 2px;
  }
  .translation__suggestions::-webkit-scrollbar-thumb {
    // box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #ddd;
  }
  .translation__suggestion {
    padding: 0.25rem 0.5rem;
  }
</style>
