<script lang="ts">
  import CreateDocument from '../lib/docs/CreateDocument.svelte'
  import { router, meta } from 'tinro'
  import { onMount } from 'svelte'
  import { ax } from '../lib/ax'
  import {
    format,
    differenceInDays,
    formatRelative,
    formatDistance,
    differenceInMinutes,
  } from 'date-fns'

  function goToDocument(e: CustomEvent<{ doc: { uid: string } }>) {
    router.goto('/documents/' + e.detail.doc.uid)
  }

  let documents: any = null

  const route = meta()
  onMount(() => {
    // getDocuments()
    const unsub = router.subscribe((v) => {
      const pageParam = v.query.page || '1'
      getDocuments(parseInt(pageParam))
    })

    return () => {
      unsub()
    }
  })

  $: documentsMeta = documents
    ? {
        total: documents.total,
        currentPage: documents.currentPage,
        totalPages: documents.totalPages,
      }
    : null

  async function getDocuments(page: number) {
    try {
      const data = await ax.$get('/api/documents/list', {
        params: {
          page,
        },
      })
      // console.log(data)
      documents = data
    } catch (error) {
      console.error(error)
    }
  }

  function changePage(inc: number) {
    const searchParms = new URLSearchParams()
    const currentPage = parseInt($route.query.page) || 1
    if (currentPage === 1 && inc === -1) {
      return
    }
    // console.log(documentsMeta)
    if (
      !documentsMeta ||
      (currentPage === documentsMeta.totalPages && inc === 1)
    ) {
      return
    }
    searchParms.set('page', (currentPage + inc).toString())
    // console.log(searchParms.toString())
    router.goto('/?' + searchParms.toString())
  }

  function formatDate(d: string) {
    let date = new Date(d)
    const diff = differenceInDays(date, new Date())
    if (diff > -30) {
      return formatDistance(date, new Date())
    } else {
      return format(date, 'MMM d, yyyy')
    }
  }
</script>

<div class="desk flex-grow">
  <div class="container">
    <!-- grid with 12 columns -->
    <div class="grid grid-cols-12 w-full gap-8">
      <div class="col-span-8">
        <div class="card bg-base-100 shadow-md">
          <div class="documents card-body">
            <h5 class="text-xl mb-4">Documents</h5>
            {#if documents}
              {#each documents.rows as doc, i (i)}
                <div class="doc">
                  <a
                    class="doc__link border flex border-base-300 p-4
                      hover:bg-base-200 hover:border-base-300
                    "
                    href={'/documents/' + doc.uid}
                  >
                    <p class="is--ml">{doc.title}</p>
                    <div class="last__updated text-base-content">
                      {formatDate(doc.updated_at)}
                    </div>
                  </a>
                </div>
              {/each}
              <nav aria-label="Page navigation example">
                <ul class="inline-flex -space-x-px text-sm">
                  <li>
                    <a
                      href="#"
                      on:click={(e) => {
                        e.preventDefault()
                        changePage(-1)
                      }}
                      class="flex items-center justify-center px-3 h-8 ml-0 leading-tight text-gray-500 bg-white border border-gray-300 rounded-l-lg hover:bg-gray-100 hover:text-gray-700"
                      >Previous</a
                    >
                  </li>
                  <li>
                    <a
                      href="#"
                      on:click={(e) => {
                        e.preventDefault()
                        changePage(1)
                      }}
                      class="flex items-center justify-center px-3 h-8 leading-tight text-gray-500 bg-white border border-gray-300 rounded-r-lg hover:bg-gray-100 hover:text-gray-700"
                      >Next</a
                    >
                  </li>
                </ul>
              </nav>
            {/if}
          </div>
        </div>
      </div>
      <div class="col-span-4">
        <CreateDocument on:create={goToDocument} />
      </div>
    </div>
  </div>
</div>
