<svg
  width="512"
  height="512"
  viewBox="0 0 512 512"
  fill="currentColor"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    d="M256 0C247.716 0 241 6.716 241 15V47C241 55.284 247.716 62 256 62C264.284 62 271 55.284 271 47V15C271 6.716 264.284 0 256 0Z"
  />
  <path
    d="M256 450C247.716 450 241 456.716 241 465V497C241 505.284 247.716 512 256 512C264.284 512 271 505.284 271 497V465C271 456.716 264.284 450 256 450Z"
  />
  <path
    d="M119.529 98.315L96.901 75.687C91.043 69.83 81.545 69.83 75.688 75.687C69.83 81.545 69.83 91.043 75.688 96.9L98.316 119.528C101.244 122.457 105.083 123.922 108.922 123.922C112.761 123.922 116.6 122.457 119.529 119.528C125.387 113.67 125.387 104.172 119.529 98.315Z"
  />
  <path
    d="M436.313 415.099L413.685 392.471C407.827 386.614 398.329 386.614 392.472 392.471C386.614 398.329 386.614 407.827 392.472 413.684L415.1 436.312C418.028 439.241 421.867 440.706 425.706 440.706C429.545 440.706 433.384 439.241 436.313 436.312C442.171 430.454 442.171 420.956 436.313 415.099Z"
  />
  <path
    d="M47 241H15C6.716 241 0 247.716 0 256C0 264.284 6.716 271 15 271H47C55.284 271 62 264.284 62 256C62 247.716 55.284 241 47 241Z"
  />
  <path
    d="M497 241H465C456.716 241 450 247.716 450 256C450 264.284 456.716 271 465 271H497C505.284 271 512 264.284 512 256C512 247.716 505.284 241 497 241Z"
  />
  <path
    d="M119.529 392.471C113.671 386.614 104.173 386.614 98.316 392.471L75.688 415.099C69.83 420.957 69.83 430.455 75.688 436.312C78.616 439.241 82.455 440.706 86.294 440.706C90.133 440.706 93.972 439.241 96.9 436.313L119.529 413.684C125.387 407.826 125.387 398.328 119.529 392.471Z"
  />
  <path
    d="M436.313 75.688C430.455 69.831 420.957 69.831 415.1 75.688L392.472 98.315C386.614 104.173 386.614 113.671 392.472 119.528C395.4 122.457 399.239 123.922 403.078 123.922C406.917 123.922 410.756 122.457 413.685 119.529L436.313 96.901C442.171 91.043 442.171 81.545 436.313 75.688Z"
  />
  <path
    d="M256 97C168.327 97 97 168.327 97 256C97 343.673 168.327 415 256 415C343.673 415 415 343.673 415 256C415 168.327 343.673 97 256 97ZM271 384.123V127.877C335.089 135.333 385 189.943 385 256C385 322.057 335.089 376.667 271 384.123Z"
  />
</svg>
