<svg
  width="434"
  height="512"
  viewBox="0 0 434 512"
  fill="currentColor"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    d="M418.641 222.827H118.437L392.687 100.723C396.349 99.093 399.213 96.075 400.65 92.332C402.086 88.589 401.977 84.429 400.347 80.767L368.381 8.972C364.986 1.345 356.052 -2.085 348.426 1.311L9.217 152.338C1.591 155.733 -1.839 164.667 1.557 172.293L32.216 241.154V496.887C32.216 505.234 38.983 512.001 47.33 512.001H418.641C426.988 512.001 433.755 505.234 433.755 496.887V237.942C433.756 229.594 426.989 222.827 418.641 222.827ZM338.252 253.056L294.148 301.418H231.485L275.589 253.056H338.252ZM234.676 253.056L190.572 301.418H127.909L172.013 253.056H234.676ZM131.102 253.056L86.998 301.418H62.444V253.056H131.102ZM324.657 44.983L346.913 35.074L366.584 79.254L304.037 107.102L324.657 44.983ZM230.036 87.112L287.282 61.624L266.662 123.743L209.416 149.231L230.036 87.112ZM135.416 129.24L192.661 103.752L172.041 165.871L114.796 191.358L135.416 129.24ZM35.32 173.804L98.041 145.88L77.421 207.998L54.99 217.985L35.32 173.804ZM403.527 481.772H62.445V331.647H403.527V481.772ZM403.527 301.418H335.06L379.165 253.056H403.527V301.418Z"
  />
</svg>
