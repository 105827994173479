import type { EditorState } from 'prosemirror-state'
import { pmSchema } from '../pm/pmSchema'
import type { ScriptSchema } from '../pm/pmSchema'
import { envSuggestions, timeSuggestions } from '../pm/pmSuggestionTypes'

export enum tourGuideState {
  INIT = 'INIT',
  ENV_ENTERED = 'ENV_ENTERED',
  ENTERING_LOCATION = 'ENTERING_LOCATION',
  POSSIBLY_ENTERED_LOCATION = 'ENTERED_LOCATION',
  ENTERING_TIME = 'ENTERING_TIME',
  ENTERED_TIME = 'ENTERED_TIME',
  EMPTY_ACTION = 'EMPTY_ACTION',
  ACTION_TYPING = 'ACTION_TYPING',
  EMPTY_CHARACTER = 'EMPTY_CHARACTER',
  ENTERED_CHARACTER = 'ENTERED_CHARACTER',
  EMPTY_DIALOGUE = 'EMPTY_DIALOGUE',
  ENTERED_DIALOGUE = 'ENTERED_DIALOGUE',
  EMPTY_PARANTHESIS = 'EMPTY_PARANTHESIS',
  ENTERED_PARANTHESIS = 'ENTERED_PARANTHESIS',
  SECOND_EMPTY_CHARACTER = 'SECOND_EMPTY_CHARACTER',
  SECOND_ENTERED_CHARACTER = 'SECOND_ENTERED_CHARACTER',

  SECOND_EMPTY_DIALOGUE = 'SECOND_EMPTY_DIALOGUE',
  SECOND_DIALOGUE_ENTERED = 'SECOND_DIALOGUE_ENTERED',

  ASK_FOR_NEW_BLOCK = 'ASK_FOR_NEW_BLOCK',

  FIRST_TRANSITION = 'FIRST_TRANSITION',

  SECOND_SCENE = 'SECOND_SCENE',

  HIDE = 'HIDE',
}

export function getTourGuideState(
  state: EditorState<ScriptSchema>
): tourGuideState {
  const { doc, selection } = state
  let node = selection.$from.node(selection.$from.depth)
  const text = node.textContent.substring(0, selection.$from.parentOffset)
  if (text.trim().length === 0 && selection.to === 1 && doc.childCount === 1) {
    return tourGuideState.INIT
  }

  if (node.type === pmSchema.nodes.sceneHeading && doc.childCount < 10) {
    let index = 0
    doc.nodesBetween(0, selection.to, (node) => {
      if (node.type === pmSchema.nodes.sceneHeading) {
        index++
      }
    })
    if (index === 2) {
      return tourGuideState.SECOND_SCENE
    }
    let trimmedText = text.trimStart()
    // check if it matches any of the env
    const envEnteredCheck = envSuggestions.find(
      (env) => trimmedText.trim().toLowerCase() === env.toLowerCase() + '.'
    )
    if (envEnteredCheck) {
      return tourGuideState.ENV_ENTERED
    }

    if (!envEnteredCheck) {
      // probably meanss user started entering location
      envSuggestions.forEach((env) => {
        if (trimmedText.startsWith(env)) {
          trimmedText = trimmedText.replace(env + '.', '')
        }
      })
      trimmedText = trimmedText.trimStart()

      if (trimmedText.length > 0 && trimmedText.indexOf('-') === -1) {
        if (trimmedText.endsWith(' ')) {
          return tourGuideState.POSSIBLY_ENTERED_LOCATION
        }
        return tourGuideState.ENTERING_LOCATION
      } else if (trimmedText.indexOf('-') !== -1) {
        const check = timeSuggestions.find((item) => {
          return trimmedText.trim().endsWith(item)
        })
        if (check) {
          return tourGuideState.ENTERED_TIME
        }
        if (trimmedText.endsWith('- ')) {
          return tourGuideState.ENTERING_TIME
        }

        return tourGuideState.POSSIBLY_ENTERED_LOCATION
      }
    }
  } else if (node.type === pmSchema.nodes.action && doc.childCount < 10) {
    let index = 0
    doc.nodesBetween(0, selection.to, (node) => {
      if (node.type === pmSchema.nodes.action) {
        index++
      }
    })
    if (index === 1) {
      if (text.trim().length === 0) {
        return tourGuideState.EMPTY_ACTION
      }
      return tourGuideState.ACTION_TYPING
    }
    return tourGuideState.HIDE
  } else if (node.type === pmSchema.nodes.character && doc.childCount < 10) {
    let index = 0
    doc.nodesBetween(0, selection.to, (node) => {
      if (node.type === pmSchema.nodes.character) {
        // console.log(node.toJSON())
        index++
      }
    })
    if (index === 2) {
      if (text.trim().length === 0) {
        return tourGuideState.SECOND_EMPTY_CHARACTER
      }
      return tourGuideState.SECOND_ENTERED_CHARACTER
    }
    if (index === 1) {
      if (text.trim().length === 0) {
        return tourGuideState.EMPTY_CHARACTER
      }
      return tourGuideState.ENTERED_CHARACTER
    }
    if (index === 3) {
      // now we can ask for a possible new block
      return tourGuideState.ASK_FOR_NEW_BLOCK
    }

    return tourGuideState.HIDE
  } else if (node.type === pmSchema.nodes.dialogue && doc.childCount < 10) {
    let index = 0
    doc.nodesBetween(0, selection.to, (node) => {
      if (node.type === pmSchema.nodes.dialogue) {
        // console.log(node.toJSON())
        index++
      }
    })
    if (index === 2) {
      if (text.trim().length === 0) {
        return tourGuideState.SECOND_EMPTY_DIALOGUE
      }
      return tourGuideState.SECOND_DIALOGUE_ENTERED
    }
    if (text.trim().length === 0) {
      return tourGuideState.EMPTY_DIALOGUE
    }
    return tourGuideState.ENTERED_DIALOGUE
  } else if (node.type === pmSchema.nodes.parenthetical) {
    let isFirstNode = true
    doc.nodesBetween(0, selection.to + 2, (tNode, pos, parent, index) => {
      if (tNode.type === pmSchema.nodes.parenthetical && tNode !== node) {
        isFirstNode = false
      }
    })
    if (isFirstNode) {
      if (text.trim() === '(') {
        return tourGuideState.EMPTY_PARANTHESIS
      } else if (text.trim().length > 2) {
        return tourGuideState.ENTERED_PARANTHESIS
      }
      return tourGuideState.HIDE
    }
    return tourGuideState.HIDE
  } else if (node.type === pmSchema.nodes.transition && doc.childCount < 10) {
    return tourGuideState.FIRST_TRANSITION
  }

  return tourGuideState.HIDE
}
