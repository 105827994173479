<script lang="ts">
  import { createEventDispatcher } from 'svelte'

  import { ax } from '../ax'
  import { showAxiosError } from '../common/toastStore'

  export let data = {
    title: '',
    description: '',
  }
  let loading = 0

  const dispatch = createEventDispatcher<{
    create: { doc: Record<string, any> }
  }>()

  async function createDocument() {
    if (loading === 1) {
      return
    }
    loading = 1
    try {
      const res = await ax.$post('/api/documents/create/default', {
        title: data.title,
        description: data.description,
      })
      if (res) {
        dispatch('create', { doc: res })
      }
    } catch (error) {
      showAxiosError(error)
    } finally {
      loading = 0
    }
  }
</script>

<div class="card bg-base-100 shadow-md">
  <div class="card-body">
    <form
      action=""
      method="post"
      on:submit={(e) => {
        e.preventDefault()
        createDocument()
      }}
    >
      <div class="form-control w-full max-w-xs">
        <label class="label" for="document-input-title">
          <span class="label-text">Title</span>
        </label>
        <input
          id="document-input-title"
          bind:value={data.title}
          type="text"
          placeholder=""
          class="input input-bordered w-full max-w-xs"
        />
      </div>
      <div class="form-control">
        <label class="label" for="document-input-description">
          <span class="label-text">Description</span>
        </label>
        <textarea class="textarea textarea-bordered h-24" placeholder="" />
      </div>
      <div class="form-control mt-4">
        <button class="btn" class:loading>Create</button>
      </div>
    </form>
    <!-- <form
      action=""
      method="post"
      on:submit={(e) => {
        e.preventDefault()
        createDocument()
      }}
    >
      <div class="field">
        <label for="weave__doc__title" class="label">Title</label>
        <input
          type="text"
          class="input"
          id="weave__doc__title"
          name="title"
          bind:value={data.title}
        />
      </div>
      <div class="field">
        <label for="weave__doc__desc" class="label">Description</label>
        <textarea
          name="description"
          rows="4"
          class="textarea"
          bind:value={data.description}
        />
      </div>
      <div class="field">
        <button
          type="submit"
          class="button is-primary"
          class:is-loading={loading}>Create</button
        >
      </div>
    </form> -->
  </div>
</div>
