<script lang="ts">
  import { createEventDispatcher } from 'svelte'
  import type { Writable } from 'svelte/store'
  import ZenModeIcon from '../common/icons/ZenModeIcon.svelte'
  import { getPageSettingsStore } from '../docs/pageSettingsStore'
  import type { DocMeta } from './docMetaStore'

  export let currentNodeType: string | null = null
  export let disableSuggestions = false
  export let method: string
  export let metaStore: Writable<DocMeta>

  const pageSettingsStore = getPageSettingsStore()

  $: loading = $metaStore.loading
  $: disabled = $metaStore.docChangedSinceSave === 0

  const dispatch = createEventDispatcher<{
    typingMethodChange: {
      typingMethod: string
    }
    save: void
  }>()
</script>

{#if $pageSettingsStore.zenMode}
  <div class="zen__tools fixed top-4 z-50 right-5">
    <!-- grid with 2 col -->
    <div class="flex flex-col items-center">
      <button
        class="btn btn-sm btn-accent"
        on:click={(e) => {
          dispatch('save')
        }}
        {disabled}
        class:loading
      >
        {#if loading}
          <span>Saving</span>
        {:else}
          <span>Save</span>
        {/if}
      </button>
    </div>
  </div>
  <div class="fixed bottom-6 left-2">
    <button
      class="btn btn-circle btn-outline mt-4 flex p-2 relative"
      on:click={(e) => {
        $pageSettingsStore.zenMode = false
      }}
    >
      <ZenModeIcon class="h-8 w-8" />
    </button>
  </div>
{/if}

<div
  class="suggestion__toolbar fixed left-0 top-0 w-full bg-base-100 z-30 light-border border-y"
  class:-mt-12={$pageSettingsStore.zenMode}
>
  <div class="flex items-center h-12">
    <div class="left flex-1" />
    <div class="page__width__wrapper flex">
      <!-- Our content should come here -->
      <div class="flex-grow">
        {#if currentNodeType}
          <p class="is--mono ml-2">{currentNodeType}</p>
        {/if}
      </div>
    </div>
    <div class="right__grow flex-1 justify-end flex items-center pr-4">
      {#if disableSuggestions}
        <div class="">
          <div class="badge badge-outline mr-4">Disabled</div>
        </div>
      {:else}
        <select
          class="select select-ghost w-auto is--mono"
          on:change={(e) => {
            dispatch('typingMethodChange', {
              typingMethod: e.target.value,
            })
          }}
          bind:value={method}
        >
          <option value="google">Manglish</option>
          <option value="ism">ISM</option>
        </select>
      {/if}

      <!-- TODO loading and disabled state -->
      <!-- <pre>{$metaStore.docChangedSinceSave}</pre> -->
      <button
        class="btn btn-sm btn-accent"
        on:click={(e) => {
          dispatch('save')
        }}
        {disabled}
        class:loading
      >
        {#if loading}
          <span>Saving</span>
        {:else}
          <span>Save</span>
        {/if}
      </button>
    </div>
  </div>
</div>

<style lang="scss">
  .page__width__wrapper {
    min-width: 21cm;
  }
</style>
