<script lang="ts">
  import { createEventDispatcher } from 'svelte'
  import type { Scene } from '../pm/docMetaStore'

  export let scenes: Scene[] = []
  const dispatch = createEventDispatcher<{
    navigate: {
      index: number
    }
  }>()
</script>

<div class="scene__list is--ml--compat">
  <ul>
    {#each scenes as scene, index (scene.id + index)}
      <li class="light-border border-b last:border-y-0 hover:bg-base-200">
        <p
          class="scene"
          on:click={(e) => {
            e.preventDefault()
            dispatch('navigate', {
              index: index,
            })
          }}
        >
          {scene.no}. {scene.text}
        </p>
        <!-- <a href={`#${scene.id}`}>{scene.text}</a> -->
      </li>
    {/each}
  </ul>
</div>

<style lang="scss">
  @import '../../styles/vars.scss';
  .scene__list {
    height: 100vh;
    overflow-y: auto;
    padding-right: 2rem;
    margin-left: 5rem;
    position: sticky;
    top: 4rem;
    // background: yellow;
    ul {
      list-style: none;
      padding: 0;
      margin: 0;
    }
    li {
      display: block;
      padding: 0.25rem;
    }
  }
  .scene:hover {
    cursor: pointer;
  }
</style>
