<svg
  width="32"
  height="32"
  viewBox="0 0 32 32"
  fill="currentColor"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    d="M24 21H26V26H24V21ZM20 16H22V26H20V16ZM11 26C9.6744 25.9984 8.40353 25.4712 7.46619 24.5338C6.52885 23.5965 6.00156 22.3256 6 21H8C8 21.5933 8.17595 22.1734 8.50559 22.6667C8.83524 23.1601 9.30377 23.5446 9.85195 23.7716C10.4001 23.9987 11.0033 24.0581 11.5853 23.9424C12.1672 23.8266 12.7018 23.5409 13.1213 23.1213C13.5409 22.7018 13.8266 22.1672 13.9424 21.5853C14.0581 21.0033 13.9987 20.4001 13.7716 19.8519C13.5446 19.3038 13.1601 18.8352 12.6667 18.5056C12.1734 18.1759 11.5933 18 11 18V16C12.3261 16 13.5979 16.5268 14.5355 17.4645C15.4732 18.4021 16 19.6739 16 21C16 22.3261 15.4732 23.5979 14.5355 24.5355C13.5979 25.4732 12.3261 26 11 26Z"
  />
  <path
    d="M28 2H4C3.46973 2.00053 2.96133 2.21141 2.58637 2.58637C2.21141 2.96133 2.00053 3.46973 2 4V28C2.00061 28.5302 2.21152 29.0386 2.58646 29.4135C2.9614 29.7885 3.46975 29.9994 4 30H28C28.5302 29.9993 29.0385 29.7883 29.4134 29.4134C29.7883 29.0385 29.9993 28.5302 30 28V4C29.9994 3.46975 29.7885 2.9614 29.4135 2.58646C29.0386 2.21152 28.5302 2.00061 28 2ZM28 11H14V4H28V11ZM12 4V11H4V4H12ZM4 28V13H28.0007L28.002 28H4Z"
  />
</svg>
