import { EditorState, Plugin, PluginKey } from 'prosemirror-state'
import type { EditorView } from 'prosemirror-view'
import type { ScriptSchema } from './pmSchema'
import { pmSchema } from './pmSchema'
import {
  findBlockNodes,
  findParentNode,
  findParentNodeClosestToPos,
} from 'prosemirror-utils'
import type { NodeType, Node } from 'prosemirror-model'
import { envSuggestions, timeSuggestions } from './pmSuggestionTypes'
import { v4 as uuidv4 } from 'uuid'

export enum Action {
  SHOW_ENV = 'env',
  SHOW_LOCATION = 'location',
  SHOW_TIME = 'time',
  SHOW_NORMAL_SUGGESTIONS = 'suggestions',
  HIDE_SUGGESTIONS = 'hideSuggestions',
  SHOW_CHARACTERS = 'characters',
  BLANK = 'blank',
  NEW_BLOCK = 'new_block',
  SHOW_TRANSITION = 'transition',
}

export type InputData = {
  start: number
  end: number
  docChanged: boolean
  action: Action | null
}

export function getTextOfBlock(state: EditorState<ScriptSchema>) {
  const selection = state.selection
  if (!selection.empty) {
    return null
  }
  if (selection.$from !== selection.$to) {
    return null
  }
  let node = selection.$from.node(selection.$from.depth)
  // console.log(node.textContent)
  // console.log(node.textContent.length)
  // console.log()
  let text = node.content.textBetween(0, selection.$from.parentOffset)
  // if there are trailing characters, like typing in between
  if (
    node.textContent.substring(selection.$from.parentOffset).length > 0 &&
    text.endsWith(' ')
  ) {
    return text.trim()
  }

  return text
}

export function getLastTypedCharacters(text: string, point: number) {
  if (!text) {
    return null
  }
  let offset = 0
  let end = text.length - 1
  while (end >= 0) {
    if (
      !text[end].match(/[a-z]+/gi) &&
      text[end].match(/([\u0D00-\u0D7F])/gi)
    ) {
      offset++
    } else {
      break
    }
    end--
  }
  // console.log(offset, 'offset')

  let textChunk = ''
  while (end >= 0) {
    if (
      !text[end].match(/[a-z]+/gi) ||
      text[end].match(/([\u0D00-\u0D7F])/gi)
    ) {
      break
    }
    textChunk = text[end] + textChunk
    end--
  }

  return {
    to: point,
    from: point - textChunk.length - offset,
    text: textChunk,
  }
}

function isEnvEntered(wordsOnBlock: string) {
  return envSuggestions.find((item) => {
    return wordsOnBlock.startsWith(item + '.')
  })
}

export function getActionAndState(
  state: EditorState<ScriptSchema>,
  detectNode?: (n: NodeType<ScriptSchema>) => void
): {
  action: Action
  word: string
} {
  const { $from } = state.selection
  const node = $from.node($from.depth)
  // console.log(node.toJSON())
  if (detectNode) {
    detectNode(node.type)
  }
  if ($from.depth === 0) {
    return {
      action: Action.HIDE_SUGGESTIONS,
      word: '',
    }
  }
  const wordsOnBlock = getTextOfBlock(state)
  if (wordsOnBlock === null) {
    // then we should return null
    return {
      action: Action.HIDE_SUGGESTIONS,
      word: '',
    }
  }
  const lastWord = getLastTypedCharacters(wordsOnBlock, $from.pos)
  // console.log({ lastWord })

  if (node.type === pmSchema.nodes.sceneHeading) {
    if (wordsOnBlock.trim().length === 0) {
      return {
        action: Action.SHOW_ENV,
        word: lastWord?.text || '',
      }
    } else {
      const locationCheck = envSuggestions.find(
        (item) =>
          wordsOnBlock.trim() === item + '.' && wordsOnBlock.endsWith(' ')
      )
      // if env is entered and something else is typed
      const isEnvEnteredWithSomeText =
        envSuggestions.find((item) => wordsOnBlock.trim().startsWith(item)) &&
        !wordsOnBlock.trim().endsWith('-')

      const timeEntered = timeSuggestions.find((item) =>
        wordsOnBlock.trim().endsWith(item)
      )
      if (locationCheck) {
        return {
          action: Action.SHOW_LOCATION,
          word: lastWord.text,
        }
      } else if (isEnvEnteredWithSomeText && !timeEntered) {
        return {
          action: Action.SHOW_NORMAL_SUGGESTIONS,
          word: lastWord.text,
        }
      } else if (timeEntered && isEnvEnteredWithSomeText) {
        return {
          action: Action.HIDE_SUGGESTIONS,
          word: lastWord.text,
        }
      } else if (
        wordsOnBlock.trim().endsWith('-') &&
        wordsOnBlock.endsWith(' ')
      ) {
        return {
          action: Action.SHOW_TIME,
          word: lastWord.text,
        }
      }
      // check if any time has been filled by the user
      const probablyTimeText = wordsOnBlock.split('-').pop().trim()
      if (
        probablyTimeText.length > 0 &&
        timeSuggestions.includes(probablyTimeText)
      ) {
        return {
          action: Action.HIDE_SUGGESTIONS,
          word: lastWord.text,
        }
      }
      // maybe env might be entered, check that
      const check = isEnvEntered(wordsOnBlock)
      if (check && lastWord.text.length > 0) {
        return {
          action: Action.SHOW_NORMAL_SUGGESTIONS,
          word: lastWord.text,
        }
      }

      return {
        action: Action.HIDE_SUGGESTIONS,
        word: lastWord.text,
      }
    }
  } else if (
    node.type === pmSchema.nodes.action ||
    node.type === pmSchema.nodes.general
  ) {
    if (!lastWord || lastWord.text.trim().length === 0) {
      return {
        action: Action.HIDE_SUGGESTIONS,
        word: '',
      }
    }
    return {
      action: Action.SHOW_NORMAL_SUGGESTIONS,
      word: lastWord.text,
    }
  } else if (node.type === pmSchema.nodes.character) {
    // if cursor is at the beginning of the block
    // and there is text after the cursor, dont show anything
    if (node.textContent.trim().length > 0 && $from.parentOffset === 0) {
      return {
        action: Action.HIDE_SUGGESTIONS,
        word: '',
      }
    }
    if (wordsOnBlock.length === 0) {
      return {
        action: Action.SHOW_CHARACTERS,
        word: '',
      }
    } else if (lastWord && lastWord.text.length > 0) {
      return {
        action: Action.SHOW_NORMAL_SUGGESTIONS,
        word: lastWord.text,
      }
    }
  } else if (
    node.type === pmSchema.nodes.dialogue ||
    node.type === pmSchema.nodes.parenthetical
  ) {
    if (wordsOnBlock.length === 0) {
      return {
        action: Action.HIDE_SUGGESTIONS,
        word: '',
      }
    } else if (lastWord && lastWord.text.length > 0) {
      return {
        action: Action.SHOW_NORMAL_SUGGESTIONS,
        word: lastWord.text,
      }
    }
  } else if (node.type === pmSchema.nodes.transition) {
    // console.log({ wordsOnBlock })
    if (node.textContent.trim().length === 0) {
      return {
        action: Action.SHOW_TRANSITION,
        word: '',
      }
    } else {
      return {
        action: Action.HIDE_SUGGESTIONS,
        word: '',
      }
    }
  } else {
    console.log(node)
    return {
      action: Action.HIDE_SUGGESTIONS,
      word: '',
    }
  }
}

class SuggestionPlugin extends Plugin<InputData, ScriptSchema> {
  constructor(pluginSpec) {
    super(pluginSpec)
  }
}

export function getSuggestionPluginAndKey(actions: {
  updateAction: (action: Action) => void
  suggestAction: (act: Action, text: string) => void
  handleKeyDown: (view: EditorView<ScriptSchema>, e: KeyboardEvent) => boolean
  detectCurrentNode: (node) => void
  onUpdateState: (
    state: EditorState<ScriptSchema>,
    { docChanged: boolean }
  ) => void
}) {
  const key = new PluginKey<InputData, ScriptSchema>('inputHelper')

  const plugin = new Plugin<InputData>({
    key,
    state: {
      init() {
        return { start: 0, end: 0, docChanged: false, action: null }
      },
      apply(tr, prev, state) {
        // console.log({
        //   doc: tr.doc.toJSON(),
        //   selection: tr.selection.toJSON(),
        // })
        return {
          start: 0,
          end: 0,
          docChanged: tr.docChanged,
          action: null,
        }
      },
    },
    view(editorView) {
      return {
        update(editorView, prevState) {
          const pluginState = key.getState(editorView.state)
          const result = getActionAndState(
            editorView.state,
            actions.detectCurrentNode
          )
          // console.log(editorView.state.toJSON())
          if (!result) {
            actions.suggestAction(Action.HIDE_SUGGESTIONS, '')
            return true
          }
          const { action, word } = result
          actions.suggestAction(action, word)
          actions.onUpdateState(editorView.state, {
            docChanged: pluginState.docChanged,
          })
          return
        },
      }
    },
    props: {
      handleKeyDown: actions.handleKeyDown,
    },
  })

  return {
    key,
    plugin,
  }
}

export const nextItemOrder = [
  [pmSchema.nodes.general, pmSchema.nodes.general],
  [pmSchema.nodes.sceneHeading, pmSchema.nodes.action],
  [pmSchema.nodes.action, pmSchema.nodes.character],
  [pmSchema.nodes.character, pmSchema.nodes.dialogue],
  [pmSchema.nodes.dialogue, pmSchema.nodes.character],
  [pmSchema.nodes.parenthetical, pmSchema.nodes.dialogue],
  [pmSchema.nodes.transition, pmSchema.nodes.sceneHeading],
]

export const tabItemOrder = [
  [pmSchema.nodes.general, pmSchema.nodes.action],
  [pmSchema.nodes.character, pmSchema.nodes.action],
  [pmSchema.nodes.action, pmSchema.nodes.character],
  [pmSchema.nodes.dialogue, pmSchema.nodes.parenthetical],
  [pmSchema.nodes.parenthetical, pmSchema.nodes.dialogue],
]

export function isSelectionAtTheEndofBlock(state: EditorState<ScriptSchema>) {
  const selection = state.selection
  const predicate = (node) => node.type !== pmSchema.nodes.doc
  const result = findParentNode(predicate)(selection)
  if (!result) {
    return false
  }
  if (
    result.node.type === pmSchema.nodes.parenthetical ||
    result.node.type === pmSchema.nodes.transition
  ) {
    return true
  }
  return result.start + result.node.nodeSize - 2 === selection.$to.pos
}

export function isCurrentNodeEmpty(state: EditorState) {
  const selection = state.selection
  const node = selection.$from.node(selection.$from.depth)
  if (node.type === pmSchema.nodes.parenthetical) {
    const textContent = node.textContent.trim().replaceAll(/[()]*/gi, '')
    return textContent.length === 0
  }
  return node.textContent.trim().length === 0
}

export function findNextTabEntry(nodeType: NodeType<ScriptSchema>) {
  const needle = tabItemOrder.find((item) => item[0] === nodeType)
  if (needle) {
    return needle[1]
  }
  return null
}

export function findNextBlock(nodeType: NodeType<ScriptSchema>) {
  const needle = nextItemOrder.find((item) => item[0] === nodeType)
  if (needle) {
    return needle[1]
  }
  return pmSchema.nodes.general
}

export function getSceneNumberAndAttrs(state: EditorState<ScriptSchema>) {
  const selection = state.selection
  let start = selection.$from.start()
  let scene: Node<ScriptSchema> | null = null
  while (start > 2) {
    let pos = state.doc.resolve(start)
    let node = pos.node()
    // console.log(node.toJSON(), start)
    if (node.type === pmSchema.nodes.sceneHeading) {
      scene = node
      break
    }
    start = pos.start() - 2
  }
  if (!scene) {
    return {
      number: 1,
      suffix: null,
    }
  }
  const numberAndVersion = scene.attrs.no
  console.log({ numberAndVersion, t: typeof numberAndVersion })
  if (typeof numberAndVersion === 'number') {
    return {
      number: numberAndVersion + 1,
      suffix: null,
    }
  }
  // split by regex number
  const numberAndSuffix = numberAndVersion
    .split(/(\d+)(\w)*/g)
    .filter((item) => item !== '')
  if (!numberAndSuffix) {
    return {
      number: 1,
      suffix: null,
    }
  }
  if (numberAndSuffix.length === 2) {
    if (numberAndSuffix[1]) {
      return {
        number: parseInt(numberAndSuffix[0]) + 1,
        suffix: String.fromCharCode(numberAndSuffix[1].charCodeAt(0) + 1),
      }
    }
    return {
      number: parseInt(numberAndSuffix[0]) + 1,
      suffix: null,
    }
  }
  return {
    number: 1,
    suffix: null,
  }
}

export function getParamsForSceneHeading(
  state: EditorState<ScriptSchema>
): Record<string, any> {
  let attrs: any = {
    id: uuidv4(),
  }
  const { number, suffix } = getSceneNumberAndAttrs(state)
  if (suffix) {
    attrs.no = `${number}${suffix}`
  } else {
    attrs.no = number
  }
  return attrs
}
