import type { NodeSpec } from 'prosemirror-model'
import { v4 as uuidv4 } from 'uuid'

export const docNode: NodeSpec = {
  content: 'block*',
}

export const paraNode: NodeSpec = {
  content: 'inline*',
  group: 'block',
  parseDOM: [{ tag: 'p' }],
  toDOM(node) {
    return ['p', 0]
  },
}

export const transition: NodeSpec = {
  content: 'inline*',
  group: 'block',
  parseDOM: [
    {
      tag: 'pmtransition',
    },
  ],
  toDOM() {
    return [
      'pmtransition',
      { class: 'custom__block pm--transition is--ml--compat' },
      0,
    ]
  },
}

export const general: NodeSpec = {
  content: 'inline*',
  group: 'block',
  parseDOM: [
    {
      tag: 'p',
    },
  ],
  toDOM() {
    return ['p', { class: 'custom__block' }, 0]
  },
}

export const parenthetical: NodeSpec = {
  content: 'inline*',
  group: 'block',
  parseDOM: [
    {
      tag: 'parenthetical',
    },
  ],
  toDOM() {
    return ['parenthetical', { class: 'custom__block is--serif' }, 0]
  },
}

export const sceneHeading: NodeSpec = {
  content: 'inline*',
  group: 'block',
  attrs: {
    no: {
      default: '1',
    },
    id: {
      default: uuidv4(),
    },
  },
  parseDOM: [
    {
      tag: 'sceneHeading',
      //@ts-ignore
      getAttrs(dom: HTMLElement) {
        let data = {
          no: '1',
          id: dom.getAttribute('id'),
        }
        if (dom.getAttribute('data-no')) {
          data.no = dom.getAttribute('data-no').replace('.', '')
        }
        return data
      },
    },
  ],
  toDOM(node) {
    // console.log(node.attrs.id)
    return [
      'sceneHeading',
      {
        'data-no': node.attrs.no + '.',
        class: 'custom__block is--ml--compat',
        id: node.attrs.id,
      },
      // ['sh-content', { class: 'custom__block', contenteditable: 'false' }, 0],
      0,
    ]
  },
}

export const action: NodeSpec = {
  content: 'inline*',
  group: 'block',
  parseDOM: [
    {
      tag: 'action',
    },
  ],
  toDOM() {
    return [
      'action',
      {
        class: 'custom__block is--serif',
      },
      0,
    ]
  },
}

export const dialogue: NodeSpec = {
  content: 'inline*',
  group: 'block',
  parseDOM: [
    {
      tag: 'dialogue',
    },
  ],
  toDOM() {
    return [
      'dialogue',
      {
        class: 'custom__block is--serif',
      },
      0,
    ]
  },
}

export const character: NodeSpec = {
  content: 'inline*',
  group: 'block',
  parseDOM: [
    {
      tag: 'character',
    },
  ],
  toDOM() {
    return [
      'character',
      {
        class: 'custom__block is--ml--compat',
      },
      0,
    ]
  },
}

export const textNode: NodeSpec = {
  group: 'inline',
}

export const hardBreak: NodeSpec = {
  inline: true,
  group: 'inline',
  selectable: false,
  parseDOM: [{ tag: 'br' }],
  toDOM() {
    return ['br']
  },
}
