/* eslint-disable */

import axios, {
  AxiosStatic,
  AxiosRequestConfig,
  AxiosResponse,
  AxiosError,
} from 'axios'

export const customAxios = axios.create({})

interface VAxiosInstance extends AxiosStatic {
  $request<T = any>(config: AxiosRequestConfig): Promise<T>
  $get<T = any>(url: string, config?: AxiosRequestConfig): Promise<T>
  $delete<T = any>(url: string, config?: AxiosRequestConfig): Promise<T>
  $head<T = any>(url: string, config?: AxiosRequestConfig): Promise<T>
  $options<T = any>(url: string, config?: AxiosRequestConfig): Promise<T>
  $post<T = any>(
    url: string,
    data?: any,
    config?: AxiosRequestConfig
  ): Promise<T>
  $put<T = any>(
    url: string,
    data?: any,
    config?: AxiosRequestConfig
  ): Promise<T>
  $patch<T = any>(
    url: string,
    data?: any,
    config?: AxiosRequestConfig
  ): Promise<T>

  setBaseURL(baseURL: string): void
  setHeader(
    name: string,
    value?: string | false,
    scopes?: string | string[]
  ): void
  setToken(
    token: string | false,
    type?: string,
    scopes?: string | string[]
  ): void

  onRequest(callback: (config: AxiosRequestConfig) => void): void
  onResponse<T = any>(callback: (response: AxiosResponse<T>) => void): void
  onError(callback: (error: AxiosError) => void): void
  onRequestError(callback: (error: AxiosError) => void): void
  onResponseError(callback: (error: AxiosError) => void): void
}

for (const method of [
  'request',
  'delete',
  'get',
  'head',
  'options',
  'post',
  'put',
  'patch',
]) {
  //@ts-ignore
  customAxios['$' + method] = function () {
    //@ts-ignore
    return this[method].apply(this, arguments).then((res) => res && res.data)
  }
}

//@ts-ignore
export const ax: VAxiosInstance = customAxios
