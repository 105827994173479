<script lang="ts">
  import type { EditorConnection } from '../pm/EditorConnection'
  import { Action } from '../pm/SuggestionPlugin'
  import { getTourGuideState, tourGuideState } from './tourGuideStateManager'
  import { fly, fade } from 'svelte/transition'
  import { createEventDispatcher, onMount } from 'svelte'

  export let ec: EditorConnection
  let state: tourGuideState = tourGuideState.HIDE

  export function onChange() {
    // console.log('onChange')
    state = getTourGuideState(ec.view.state)
    // console.log({ state })
    // console.log(ec.view.state.toJSON())
  }
  const tranInOptions = {
    duration: 10,
    delay: 100,
  }
  const transOutOptions = {
    duration: 10,
  }

  let action: Action = Action.BLANK
  let hasSuggestions = false
  let suggestions: string[] = []

  export function updateActionAndSuggestionsState(
    e: CustomEvent<{
      action: Action
      hasSuggestions: boolean
      suggestions: string[]
    }>
  ) {
    const detail = e.detail
    action = detail.action
    hasSuggestions = detail.hasSuggestions
    suggestions = detail.suggestions
  }

  $: askForNewBlock = isNewBlockReadyForCreation(state, suggestions)

  onMount(() => {
    onChange()
  })

  function isNewBlockReadyForCreation(
    state: tourGuideState,
    suggestions: string[]
  ): any {
    if (state === tourGuideState.ASK_FOR_NEW_BLOCK) {
      if (suggestions.length === 0) {
        return true
      }
      if (!suggestions[0].startsWith('Scene')) {
        return true
      }
      if (suggestions.length > 0 && suggestions[0].startsWith('Scene')) {
        return false
      }
    }
    return false
  }

  const dispatch = createEventDispatcher<{
    dismiss: {}
  }>()
</script>

<!-- <pre>{askForNewBlock}</pre>
<pre>{state}</pre>
<pre>{hasSuggestions}</pre> -->

<div class="tg">
  <div
    class="tour__guide card shadow-lg bg-base-100 light-border border"
    class:hidden={state === tourGuideState.HIDE}
  >
    <div class="card-body">
      <div class="tg__steps">
        {#if state === tourGuideState.INIT}
          <div
            class="step is--size-7"
            in:fade={tranInOptions}
            out:fade={transOutOptions}
          >
            <h4 class="is--ml text-lg font-semibold">സ്വാഗതം</h4>
            <div class="is--ml--compat">
              <p>എവിടെയാണ് നിങ്ങളുടെ സീൻ നടക്കുന്നത്?</p>
              <p>
                അകത്താണേൽ <span class="high">INT</span> കൊടുത്തോ. പുറത്താണേൽ
                <span class="high">EXT</span>.
              </p>
              <p>
                ഇനി അകത്തുമല്ല പുറത്തുമല്ല എന്നിരിക്കട്ടെ. ഉദാഹരണത്തിന് ഒരു ഫോൺ
                ബൂത്ത്, പടിവാതിൽ, സിറ്റ്ഔട്ടിൽ നിന്നിറങ്ങുന്ന രംഗം. <br />
                അപ്പോൾ
                <span class="high">INT/EXT</span>
                കൊടുക്കണം.
              </p>
              <p>
                Use your mouse click or keyboards arrow keys to navigate to the
                option and press enter to select
              </p>
            </div>
          </div>
        {/if}
        {#if state === tourGuideState.ENV_ENTERED}
          <div
            class="step is--size-7"
            in:fade={tranInOptions}
            out:fade={transOutOptions}
          >
            <h4 class="is--ml text-lg font-semibold">ഓക്കേ സെറ്റ്!</h4>
            <div class="is--ml--compat">
              <p>
                ഇനി സീൻ നടക്കുന്ന <span class="high">location</span> ടൈപ്പ് ചെയ്യൂ
              </p>
              <p>
                നിങ്ങൾ മംഗ്ളീഷില് ടൈപ്പ് ചെയ്യുന്നതിന് അനുസരിച്ചു നിങ്ങൾക്ക്
                മലയാളം സജഷൻസ് വരും. ഓരോ വാക്ക് കഴിയുമ്പോഴും സെലക്ട് ചെയ്തു
                കൊടുത്താൽ മതിയാകും.
              </p>
            </div>
          </div>
        {/if}
        {#if (state === tourGuideState.ENTERING_LOCATION || state === tourGuideState.POSSIBLY_ENTERED_LOCATION) && !hasSuggestions}
          <div
            class="step is--size-7"
            in:fade={tranInOptions}
            out:fade={transOutOptions}
          >
            <h4 class="is--ml text-lg font-semibold">ലൊക്കേഷൻ റെഡി</h4>
            <div class="is--ml--compat">
              <p>ഇനി സീൻ നടക്കുന്ന സമയമാണ് പറഞ്ഞുകൊടുക്കേണ്ടത്.</p>
              <p>
                ലൊക്കേഷൻ ടൈപ്പ് ചെയ്തു കഴിഞ്ഞാൽ, <code>Space</code> വിട്ടതിനു
                ശേഷം ഹൈഫൺ <code>-</code>പ്രെസ്സ് ചെയ്ത് സ്‌പേസ്
                <code>space</code> അമർത്തുക
              </p>
              <p>അതായത്, <code>Space</code><code>-</code><code>Space</code></p>
            </div>
          </div>
        {/if}
        {#if state === tourGuideState.ENTERING_TIME}
          <div
            class="step is--size-7"
            in:fade={tranInOptions}
            out:fade={transOutOptions}
          >
            <h4 class="is--ml text-lg font-semibold">സമയം സെലക്റ്റ് ചെയ്യൂ</h4>
            <div class="is--ml--compat">
              <p>ഇനി സീൻ നടക്കുന്ന സമയമാണ് പറഞ്ഞുകൊടുക്കേണ്ടത്.</p>
            </div>
          </div>
        {/if}
        {#if state === tourGuideState.ENTERED_TIME}
          <div
            class="step is--size-7"
            in:fade={tranInOptions}
            out:fade={transOutOptions}
          >
            <h4 class="is--ml text-lg font-semibold">അടിപൊളി!</h4>
            <div class="is--ml--compat">
              <p>
                അപ്പൊ നമ്മൾ സീൻ ഹെഡിങ് എഴുതി കഴിഞ്ഞിരിക്കുന്നു. പൊതുവിൽ
                ഇങ്ങനെയാണ് ഒരു സീൻ ഹെഡിങ്ങിന്റെ ഫോർമാറ്റ്
              </p>
              <p>
                ഇനി നമുക്ക് <span class="high">'ഏക്ഷൻ'</span> എഴുതാം! Press
                <span class="badge badge-outline">Enter</span>
              </p>
            </div>
          </div>
        {/if}
        {#if state === tourGuideState.EMPTY_ACTION || state === tourGuideState.ACTION_TYPING}
          <div
            class="step is--size-7"
            in:fade={tranInOptions}
            out:fade={transOutOptions}
          >
            <h4 class="is--ml text-lg font-semibold">സുമതി ഓടി!!</h4>
            <div class="is--ml--compat">
              <p>
                ഇപ്പോൾ നിങ്ങൾക്ക് സ്ക്രീനിനു താഴെ ഇടതുവശത്തു <span class="high"
                  >action</span
                >
                എന്ന് കാണാൻ കഴിയും. നിങ്ങളുടെ <span class="high">cursor</span>
                <span class="blinking-cursor">|</span>
                ഇരിക്കുന്ന ബ്ലോക്ക് ഏതാണെന്ന് എപ്പോഴും അവിടെ കാണാൻ സാധിക്കും.
              </p>

              <p>
                അപ്പൊ തുടങ്ങിക്കോ..!
                <br />
                <br />
                <b>കിഴക്കേടപാടമെന്ന അതിമനോഹരമായ ഗ്രാമം!</b>
              </p>
              <p>
                <span class="high">action</span>
                എഴുതി കഴിഞ്ഞാൽ വെറുതെ
                <span class="badge badge-outline">Enter</span> അടിച്ചോ. ബാക്കി അപ്പൊ
                പറയാം..
              </p>
            </div>
          </div>
        {/if}

        {#if state === tourGuideState.EMPTY_CHARACTER || state === tourGuideState.ENTERED_CHARACTER}
          <div
            class="step is--size-7"
            in:fade={tranInOptions}
            out:fade={transOutOptions}
          >
            <h4 class="is--ml text-lg font-semibold">Character</h4>
            <div class="is--ml--compat">
              <p>ഇപ്പോൾ നിങ്ങൾക്ക് കഥാപാത്രത്തിന്റെ പേര് ചേർക്കാം.</p>
              <p>
                ചേർത്ത് കഴിഞ്ഞിട്ട് <span class="badge badge-outline"
                  >Enter</span
                > അടിച്ചോ
              </p>
            </div>
          </div>
        {/if}

        {#if state === tourGuideState.EMPTY_DIALOGUE || state === tourGuideState.ENTERED_DIALOGUE}
          <div class="step is--size-7">
            <h4 class="is--ml text-lg font-semibold">Dialogue</h4>
            <div class="is--ml--compat">
              <p>
                <span class="high">character</span>
                ബ്ലോക്ക് കഴിഞ്ഞു <span class="high">dialogue</span> ആണ് പൊതുവേ വരാറ്.
              </p>
              <p>ചുമ്മാ ഒരു സാമ്പിൾ ഡയലോഗ് എഴുതിക്കോ!</p>
              <p>
                And press
                <span class="badge badge-outline">Enter</span>
              </p>
            </div>
          </div>
        {/if}

        {#if state === tourGuideState.SECOND_EMPTY_CHARACTER}
          <div class="step is--size-7">
            <h4 class="is--ml text-lg font-semibold">One more Character</h4>
            <div class="is--ml--compat">
              <p>You can choose from the list or create a new one</p>
            </div>
          </div>
        {/if}

        {#if state === tourGuideState.SECOND_EMPTY_DIALOGUE}
          <div class="step is--size-7">
            <h4 class="is--ml text-lg font-semibold">Dialogue/Parenthetical</h4>
            <div class="is--ml--compat">
              <p>
                You can use <code>Tab</code> to toggle
                <span class="high">dialogue</span>
                and <span class="high">parenthetical</span>
              </p>
            </div>
          </div>
        {/if}

        {#if state === tourGuideState.EMPTY_PARANTHESIS}
          <div class="step is--size-7">
            <h4 class="is--ml text-lg font-semibold">Parenthetical</h4>
            <div class="is--ml--compat">
              <p>
                Try something like (ദേഷ്യത്തോടെ) and press <span
                  class="badge badge-outline">Enter</span
                > to continue.
              </p>
              <p>
                You can go change the current block to <span class="high"
                  >dialogue</span
                >
                by pressing <code>Tab</code> again.
              </p>
            </div>
          </div>
        {/if}
      </div>

      {#if askForNewBlock}
        <div class="step is--size-7">
          <h4 class="is--ml text-lg font-semibold">Lets make a new scene</h4>
          <div class="is--ml--compat">
            <p>We can start by doing a <span class="high">transition</span></p>
            <ul>
              <li>
                <p>
                  Press <code>Esc</code> to hide current character selections
                </p>
              </li>
              <li>
                <p>
                  Once character suggestions are hidden, press <code>Enter</code
                  >
                </p>
              </li>
            </ul>
          </div>
        </div>
      {/if}

      {#if state === tourGuideState.ASK_FOR_NEW_BLOCK && suggestions.length > 0 && suggestions[0].startsWith('Scene')}
        <div class="step is--size-7">
          <h4 class="is--ml text-lg font-semibold">Transition</h4>
          <div class="is--ml--compat">
            <p>
              Select <span class="high">Transition</span>
              from the list
            </p>
          </div>
        </div>
      {/if}

      {#if state === tourGuideState.FIRST_TRANSITION}
        <div class="step is--size-7">
          <h4 class="is--ml text-lg font-semibold">Nice!</h4>
          <div class="is--ml--compat">
            <p>
              Select a <span class="high">transition</span>
              from the list and press
              <span class="badge badge-outline">Enter</span> to go to a new scene
            </p>
          </div>
        </div>
      {/if}

      {#if state === tourGuideState.SECOND_SCENE}
        <div class="step is--size-7">
          <h4 class="is--ml text-lg font-semibold">Congrats!</h4>
          <div class="is--ml--compat">
            <p>You are all set to write a new script</p>
            <p>Remember these tips</p>
            <p />
            <ul>
              <li>
                When you dont need a suggestion, press <code>Esc</code>
              </li>
              <li>
                When you need a new <span class="high">block</span>, press
                <span class="badge badge-outline">Enter</span> by placing cursor
                on any blank block. Suggestions for new block will appear.
              </li>
              <li>
                Press <code>Tab</code> to toggle between blocks like
                <span class="high">character</span>
                and <span class="high">action</span>
              </li>
            </ul>
            <div class="field">
              <button
                on:click={(e) => {
                  e.preventDefault()
                  dispatch('dismiss')
                }}
                class="btn btn-sm btn-accent">Dismiss</button
              >
            </div>
          </div>
        </div>
      {/if}
    </div>
  </div>
</div>

<style lang="scss">
  @import '../../styles/vars.scss';
  .tg {
    position: fixed;
    bottom: 0;
    right: 0;
    max-width: 360px;
    margin-bottom: 4rem;
    margin-right: 2rem;
  }
  .tour__guide {
    position: absolute;
    right: 0;
    bottom: 0;
    width: 360px;
    overflow: hidden;
  }
  .tg__steps {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
  }
  .step {
    display: flex;
    flex-direction: column;
    margin-right: 1.25rem;
  }
  .is--size-7 {
    font-size: 0.95rem;
    line-height: 1.3;
  }
  p:not(:last-child) {
    margin-bottom: 0.5rem;
  }
  .high {
    background: $yellow;
    padding: 2px 4px;
    border-radius: 4px;
    display: inline-block;
    margin-top: 5px;
  }
  ul {
    list-style: square;
    padding-left: 1rem;
  }
  li:not(:last-child) {
    margin-bottom: 0.5rem;
  }
  .field {
    margin-top: 1rem;
  }

  .blinking-cursor {
    color: $black;
    animation: cursor-blink 1.5s tg__steps(2) infinite;
  }
  :global {
    @keyframes cursor-blink {
      0% {
        opacity: 0;
      }
    }
  }
</style>
