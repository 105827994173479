import { Schema } from 'prosemirror-model'
import {
  action,
  character,
  dialogue,
  docNode,
  general,
  hardBreak,
  paraNode,
  parenthetical,
  sceneHeading,
  textNode,
  transition,
} from './nodes'

export const pmSchema = new Schema({
  nodes: {
    doc: docNode,
    sceneHeading: sceneHeading,
    general: general,
    text: textNode,
    action: action,
    paragraph: paraNode,
    dialogue: dialogue,
    character: character,
    parenthetical: parenthetical,
    transition: transition,
    hard_break: hardBreak,
  },
  marks: {},
})

export type ScriptSchema = typeof pmSchema
